import React from 'react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const ProgramDetails = () => {
  const { programName } = useParams();
  console.log(programName);

  const getFigmaUrl = (program) => {
    switch (program) {
      case 'stay-fit-and-healthy':
        return 'https://embed.figma.com/proto/mjVEdOILAHhI5nANQiY9av/Untitled?scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&node-id=2-5&starting-point-node-id=2%3A5&show-proto-sidebar=1&embed-host=share';
      case 'eczema-atopic-dermatitis':
        return 'https://embed.figma.com/proto/8tCKERglMdoar22u32arWB/Untitled?node-id=2-3&node-type=canvas&scaling=min-zoom&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=2%3A3&embed-host=share';
      default:
        return ''; // Return empty or a default prototype URL
    }
  };
//   const [currentScreen, setCurrentScreen] = useState('initial');
  // Define screens and hotspots for each program
//   const programData = {
//     'stay-fit-and-healthy': {
//       initial: {
//         image: 'https://appcdn.goqii.com/storeimg/70439_1733211354.jpg',
//         hotspots: [
//           {
//             id: 1,
//             position: { top: 100, left: 20, width: 200, height: 80 },
//             nextScreen: 'screen1'
//           },
//           {
//             id: 2,
//             position: { top: 300, left: 40, width: 180, height: 60 },
//             nextScreen: 'screen2'
//           }
//         ]
//       },
//       screen1: {
//         image: 'https://storage.googleapis.com/ksabupatest/2024/12/03/8ak48/x43ec3hhd3.jpg',
//         hotspots: [
//           {
//             id: 1,
//             position: { top: 150, left: 30, width: 220, height: 70 },
//             nextScreen: 'screen3'
//           }
//         ]
//       },
//       screen2: {
//         image: '/api/placeholder/280/500',
//         hotspots: []
//       },
//       screen3: {
//         image: '/api/placeholder/280/500',
//         hotspots: []
//       }
//     },
//     'diabetes': {
//       initial: {
//         image: '/api/placeholder/280/500',
//         hotspots: [
//           {
//             id: 1,
//             position: { top: 120, left: 30, width: 180, height: 70 },
//             nextScreen: 'screen1'
//           }
//         ]
//       },
//       // Add more screens for diabetes program
//     },
//     // Add more programs as needed
//   };

  // Get current program's data or use a default
//   const currentProgramData = programData[programName] || programData['stay-fit-and-healthy'];
//   const currentScreenData = currentProgramData[currentScreen];

  // Navigation history
//   const [history, setHistory] = useState(['initial']);

//   const goToScreen = (screenName) => {
//     setCurrentScreen(screenName);
//     setHistory(prev => [...prev, screenName]);
//   };

//   const goBack = () => {
//     if (history.length > 1) {
//       const newHistory = [...history];
//       newHistory.pop();
//       const previousScreen = newHistory[newHistory.length - 1];
//       setCurrentScreen(previousScreen);
//       setHistory(newHistory);
//     }
//   };

//   const canGoBack = history.length > 1;
//   const canGoForward = currentScreenData?.hotspots?.length > 0;

  return (
    <div className="tw-bg-white tw-py-2.5 tw-relative tw-rounded-2xl tw-mr-6">
      <div
        className="tw-fixed tw-inset-0 tw-backdrop-blur-md tw-z-0 tw-bg-white/30"
        style={{
          backgroundImage: `url(https://appcdn.goqii.com/storeimg/82809_1733314164.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 0.4,
        }}
      />
      <div className="tw-max-w-4xl tw-mx-auto tw-px-4 ">
        <div className="tw-flex tw-justify-center tw-items-center tw-relative tw-h-[80vh]">
          {/* Back Button */}
          {/* {canGoBack && (
            <button
              onClick={goBack}
              className="tw-absolute tw-left-4 tw-top-1/2 tw--translate-y-1/2 tw-bg-white tw-p-2 tw-rounded-full tw-shadow-lg hover:tw-bg-gray-50"
            >
              <ChevronLeft className="tw-w-6 tw-h-6" />
            </button>
          )} */}

<div className="tw-relative tw-w-[385px] tw-h-[840px] tw-scale-[0.65]">
      {/* Device frame */}
      {/* <div className="tw-absolute tw-inset-0 tw-bg-black tw-rounded-[3rem]">
       
        <div className="tw-absolute tw-top-0 tw-left-1/2 -tw-translate-x-1/2 tw-w-[120px] tw-h-7 tw-bg-black tw-rounded-b-3xl" />
      </div> */}
       <img 
              src="https://storage.googleapis.com/ksabupatest/2024/12/02/58q4r/xokhw4taqh.png"
              alt="mobile-mockup"
              className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full pointer-events-none" 
            />
      
      {/* Screen container */}
      <div className="tw-absolute tw-top-[14px] tw-left-[14px] tw-right-[14px] tw-bottom-[14px] tw-z-0 tw-rounded-[3.5rem] tw-bg-white tw-overflow-y-auto [&::-webkit-scrollbar]:tw-hidden [-ms-overflow-style:none] [scrollbar-width:none]">
        <iframe
          className="tw-w-full tw-h-[106%] tw-object-cover"
          src={getFigmaUrl(programName)}
          allowFullScreen
        />
{/* <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://embed.figma.com/proto/mjVEdOILAHhI5nANQiY9av/Untitled?scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&node-id=2-5&starting-point-node-id=2%3A5&show-proto-sidebar=1&embed-host=share" allowfullscreen></iframe>*/}      
</div> 
    </div>

          {/* Forward Indicator (if hotspots exist) */}
          {/* {canGoForward && (
            <div className="tw-absolute tw-right-4 tw-top-1/2 tw--translate-y-1/2 tw-bg-white tw-p-2 tw-rounded-full tw-shadow-lg">
              <ChevronRight className="tw-w-6 tw-h-6 tw-text-gray-400" />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ProgramDetails;