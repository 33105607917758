import { Box, FormHelperText, InputLabel } from '@mui/material'
import React, { useState } from 'react'
import { InputTypeProps } from '../../../utils/SurveyFormData'
import FormErrorBox from './FormErrorBox';
import "../SurveyForm.css";

const TextAreaBox = ({ question_text, order, responseValue, onResponseChange, question_options, error, disabled, currentSelectedLanguage }: InputTypeProps) => {

  const [value, setValue] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    if (onResponseChange) {
      onResponseChange({ response_text: e.target.value })
    }
  };

  return (
    <Box display='flex' flexDirection='column' alignItems="flex-start" sx={{
      '& > :not(style)': { m: 1 }, m: 2, p: 2, direction: disabled && currentSelectedLanguage && currentSelectedLanguage[0].value === "ar" ? "rtl" : "unset",
    }}>
      {error && <FormErrorBox error={error} />}
      <InputLabel htmlFor="my-input" sx={{ whiteSpace: "wrap", textAlign: "left", color: "black"  }}>{question_options?.some((data: any) => data.validation === 'required') && "* "}{order + ") "}{question_text}</InputLabel>
      {disabled ? (
        <textarea className='custom_textarea' cols={50} rows={3} name='demo-textarea' disabled={disabled} />
      ) : (
        <textarea className='custom_textarea' cols={50} rows={3} name='demo-textarea' onChange={handleChange} value={responseValue?.response_text || ""} />
      )}
    </Box>
  )
}

export default TextAreaBox