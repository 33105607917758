import React, { useEffect, useState } from 'react';
import './LoginPage.css';
import LanguageSetting from './componenents/LanguageSetting';
import axios from "axios";
import { CustomFieldLevelLoader, decruptCipherString } from './utils/CommonLibrary';
import { encryptData } from './utils/Utils';

function LoginPage() {

    const [url, setUrl] = useState('');
    const [loading, showLoading] = useState(true);

    useEffect(() => {

        let isLoggedIn = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : '';
        if (isLoggedIn) {
            const redirectUrl = decruptCipherString(isLoggedIn);
            window.location.href = redirectUrl;
        }

        const fetchData = async () => {
            axios.post(process.env.REACT_APP_BASE_URL + 'sso/login', {
            })
                .then(function (response) {

                    if (response.data.code == 200) {
                        setUrl(decruptCipherString(response.data.data.link));
                        showLoading(false);
                    } else {
                        alert('something went wrong');
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        };

        fetchData();
    }, []);

    const redirectUrl = () => {
        localStorage.setItem("preLogin", encryptData("Yes"));
        showLoading(true);
        window.location.href = url;
    }

    return (
        <>
            <section className='LoginPage'>
                {/* <div className='language-setting'>
                <LanguageSetting/>
            </div> */}
                <div className="card-login tw-overflow-hidden">
                    <div className="card-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 ps-md-0 pe-md-5 en-ar-text-align">
                                    <img src="https://storecdn.goqii.com/media/store-img/goqii-logo_store.png" className="mt-4 ms-4" alt="" />
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "36px", margin: "20% auto", width: "80%" }}>
                                        <img src="https://appcdn.goqii.com/storeimg/80972_1727955999.png" className="tw-w-[400px]" />
                                        {/* <div className="form-group mb-2">
                                    <input type="text" className="form-control control-input" id="Username" placeholder="Username" />
                                    <img src={require("../images/icon-username.png")} className="input-icon" alt="" />
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control control-input" id="Password" placeholder="Password" />
                                    <img src={require("../images/icon-password.png")} className="input-icon" alt="" />
                                </div> */}
                                        <div className="form-group text-center">
                                            {!loading ?
                                                <button type="button" className="btn btn-primary btn-login tw-flex tw-mx-auto tw-items-center" onClick={redirectUrl}><img src="https://cdn-icons-png.flaticon.com/512/2702/2702602.png" style={{ height: "36px" }} className='me-2' alt="" /> SSO Sign in</button>
                                                :
                                                <div className='tw-flex tw-justify-center tw-items-center tw-w-full'>
                                                    <CustomFieldLevelLoader />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 px-0">
                                    <img src="https://appcdn.goqii.com/storeimg/20630_1732785232.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='powered-by mt-2'>
                    <img src="https://appcdn.goqii.com/storeimg/91809_1719235434.png" style={{ height: "42px" }} className="" alt="" />
                </div>
            </section>
        </>
    );
}

export default LoginPage;
