import React, { Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import { InputLabel } from '@mui/material';
import { InputTypeProps, RatingOptionProps } from '../../../utils/SurveyFormData';
import FormErrorBox from './FormErrorBox';
import Smileys from '../CustomComponents/Smileys';

const CSATBox = ({ question_text, order, responseValue, onResponseChange, question_options, error, disabled, currentSelectedLanguage }: RatingOptionProps) => {
    const [selectedSmiley, setSelectedSmiley] = useState<number | null>(null);

    const handleSmileyClick = (level: number) => {
        setSelectedSmiley(level);
        if (onResponseChange) {
            onResponseChange({ csat_value: level });
        }
    };

    const currentData = currentSelectedLanguage ? currentSelectedLanguage[0].value === "en" ? (
        [
            { level: 1, label: 'Very Unsatisfied' },
            { level: 2, label: 'Unsatisfied' },
            { level: 3, label: 'Neutral' },
            { level: 4, label: 'Satisfied' },
            { level: 5, label: 'Very Satisfied' }
        ]
    ) : (
        [
            { level: 1, label: 'غير راضٍ جدًا' },
            { level: 2, label: 'غير راض' },
            { level: 3, label: 'حيادي' },
            { level: 4, label: 'راضي' },
            { level: 5, label: 'راض جدا' }
        ]
    ) : [];

    return (
        <Box display='flex' flexDirection='column' alignItems="flex-start" gap={1} sx={{
            '& > :not(style)': { m: 1 }, m: 1, p: 1, direction: disabled && currentSelectedLanguage && currentSelectedLanguage[0].value === "ar" ? "rtl" : "unset",
        }}>
            {error && <FormErrorBox error={error} />}
            <InputLabel htmlFor="my-input" sx={{ whiteSpace: "wrap", textAlign: "left", }}>{question_options?.some((data: any) => data.validation === 'required') && "* "}{order + ") "}{question_text}</InputLabel>
            <Box
                display='flex'
                justifyContent='space-around'
                width='100%'
            >
                {currentData.map(({ level, label }) => (
                    <Fragment key={level}>
                        {disabled ? (
                            <Smileys
                                // key={level}
                                level={level}
                                label={label}
                                isSelected={false}
                                onSmileyClick={handleSmileyClick}

                            />
                        ) : (
                            <Smileys
                                // key={level}
                                level={level}
                                label={label}
                                isSelected={level === selectedSmiley || responseValue?.csat_value === level}
                                onSmileyClick={handleSmileyClick}
                            />
                        )}


                    </Fragment>
                ))}
            </Box>
        </Box>
    );
}

export default CSATBox;


// import React, { useState, ChangeEvent } from 'react';
// import { Smile, Meh, Frown } from 'lucide-react';

// interface CSATBoxProps {
//   questionText: string;
//   order: number;
//   onResponseChange: (response: { csat_value: number | null; feedback: string }) => void;
//   isRequired?: boolean;
// }

// interface Rating {
//   value: number;
//   label: string;
//   icon: React.ElementType;
//   color: string;
// }

// const CSATBox: React.FC<CSATBoxProps> = ({ questionText, order, onResponseChange, isRequired = false }) => {
//   const [selectedRating, setSelectedRating] = useState<number | null>(null);
//   const [feedback, setFeedback] = useState<string>('');

//   const ratings: Rating[] = [
//     { value: 1, label: '', icon: Frown, color: 'text-red-500' },
//     { value: 2, label: '', icon: Meh, color: 'text-yellow-500' },
//     { value: 3, label: '', icon: Smile, color: 'text-green-500' },
//     { value: 4, label: '', icon: Smile, color: 'text-blue-500' },
//     { value: 5, label: '', icon: Smile, color: 'text-purple-500' },
//   ];

//   const handleRatingClick = (value: number) => {
//     setSelectedRating(value);
//     onResponseChange({ csat_value: value, feedback });
//   };

//   const handleFeedbackChange = (e: ChangeEvent<HTMLInputElement>) => {
//     setFeedback(e.target.value);
//     if (onResponseChange) {
//       onResponseChange({ csat_value: selectedRating, feedback: e.target.value });
//     }
//   };

//   return (
//     <div className="bg-white shadow-md rounded-lg p-6 my-4">
//       <h3 className="text-left ml-2 mb-4">
//         {isRequired && <span className="text-red-500 mr-1">*</span>}
//         {order} {questionText}
//       </h3>
//       <div className="flex flex-col space-y-4">
//         {ratings.map(({ value, label, icon: Icon, color }) => (
//           <div key={value} className="flex items-center space-x-4">
//             <div
//               className={`flex items-center cursor-pointer transition-all duration-200 ${
//                 selectedRating === value ? 'transform scale-110' : ''
//               }`}
//               onClick={() => handleRatingClick(value)}
//             >
//               <Icon
//                 size={32}
//                 className={`${color} ${
//                   selectedRating === value ? 'animate-bounce' : ''
//                 }`}
//               />
//               <span className={`ml-2 ${color}`}>{label}</span>
//             </div>
//             <input
//               type="text"
//               placeholder={`Enter your feedback for ${label}`}
//               className={`flex-grow p-2 border rounded-md ${
//                 selectedRating === value ? 'border-blue-500' : 'border-gray-300'
//               }`}
//               onChange={handleFeedbackChange}
//               disabled={selectedRating !== value}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default CSATBox;