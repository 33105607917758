import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Copy, ArrowLeft } from "lucide-react";
import RDPSelect from "react-dropdown-select";
import { fetchSurveys, copySurveyBuilder, fetchSurvey } from "../../services/SurveyFormService";
import SurveyPreview from "./FormBuilders/SurveyPreview";
import { languageOptions, SurveyFormDataBuilderTS } from "../../utils/SurveyFormData";
import SurveyLogo from "../../survey_logo1.png"
import { SelectOptionType } from "../../utils/CommunicationFormData";

const CopySurveyPage = () => {
  const platform = "goqii";
  const navigate = useNavigate();
  const [preview, setPreview] = useState(false);
  const [previewData, setPreviewData] = useState<SurveyFormDataBuilderTS>();
  const [selectedSurvey, setSelectedSurvey] = useState<number>(0);
  const [selectedFeedbackOptions, setSelectedFeedbackOptions] = useState<SelectOptionType[]>([]);
  const [communicateFormErrors, setCommunicateFormErrors] = useState({});
  const [feedbackOptions, setFeedbackOptions] = useState([]);
  const [communicateForm, setCommunicateForm] = useState({
    channel: "",
    sms_name: "",
    trigger_point: "",
    trigger_time: "",
    trigger_interval_time: "",
    feedback_name: "",
    sms_message:
      "Please take a moment to shared your feedback about the call with {name}. Click here to give feedback - {link}",
    language: "English",
  });
  const [surveyNumber, setSurveyNumber] = useState(1);
  const [selectedLanguageOptions, setSelectedLanguageOptions] = useState<SelectOptionType[]>([]);

  const handleCopy = () => {
    if (selectedSurvey) {
      handleCopySurvey(selectedSurvey);
    }
  };

  const handleCopySurvey = async (surveyId: number) => {
    try {
      const payload = { id: surveyId, user_id: 1 };
      const { data } = await copySurveyBuilder(payload);
      if (data.status === "success") {
        // alert(data.message);
        console.log(data);
        fetchAllSurveys(platform);
        navigate(`/feedback360/create/${data.response.id}`);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Failed to copy survey:", error);
    }
  };

  const handlePreview = async () => {
    if (selectedSurvey) {
      try {
        // const payload = { id: selectedSurvey, user_id: 1 };
        // console.log('Payload:', payload); // Log the payload to inspect it
        const { data } = await fetchSurvey(selectedSurvey);
        if (data.status === "success") {
          setPreviewData(data.response);

          const fetchedLanguageOptions = data.response.translations.map((translation: any) => {
            const matchedOption = languageOptions.find(option => option.value === translation.language_code);
            return matchedOption ? matchedOption : null;
          }).filter(Boolean);
  
          setSelectedLanguageOptions(fetchedLanguageOptions);

          setPreview(true);
        } else {
          console.error('Error message from server:', data.message);
        }
      } catch (error) {
        console.error("Failed to preview survey:", error);
      }
    }
  };


  useEffect(() => {
    fetchAllSurveys(platform);
  }, []);

  const fetchAllSurveys = async (platform: string) => {
    const payload = {
      platform: platform,
      survey_type: "custom",
      // filter: filter
    };
    try {
      const { data } = await fetchSurveys(payload);
      if (data.status === "success") {
        setFeedbackOptions(
          data.response.map((survey: any) => ({
            value: survey.id,
            label: `${survey.title} (${survey.template_name || ""})`,
          }))
        );
        console.log(data.response);
      } else {
        console.log(data.message);
        alert(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFeedbackOptionsChange = (selected: any) => {
    if (selected.length > 0) {
      setSelectedFeedbackOptions(selected);
      setSelectedSurvey(selected[0].value);

      setCommunicateForm((prev) => ({
        ...prev,
        feedback_name: selected[0].label,
      }));

      setCommunicateFormErrors({
        ...communicateFormErrors,
        feedback_name: "",
      });
    }
  };

  return (
    <div className="tw-min-h-[85vh] tw-mx-auto tw-px-4 tw-pb-8">
      <motion.h1
        className="tw-text-3xl md:tw-text-4xl tw-font-bold tw-text-center tw-text-gray-800 tw-mb-8"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Copy Existing Survey
      </motion.h1>
      <motion.div
        className="tw-max-w-md tw-mx-auto tw-bg-white tw-rounded-lg tw-shadow-lg"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="tw-p-6">
          <div className="tw-mb-6">
            <label
              htmlFor="survey-select"
              className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-2"
            >
              Select Survey to Copy:
            </label>
            <RDPSelect
              options={feedbackOptions}
              values={selectedFeedbackOptions}
              onChange={handleFeedbackOptionsChange}
              dropdownHeight="150px"
              placeholder="Select a survey"
              style={{
                width: "100%",
                textAlign: "left",
                marginBottom: "20px",
                zIndex: 50,
                fontSize: "18px",
                borderRadius: "16px",
              }}
            />
          </div>
          <div className="tw-flex tw-justify-center tw-gap-4">
            <motion.fieldset
              onClick={handlePreview}
              disabled={!selectedSurvey}
              className={`tw-w-full tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-[#03335b] hover:tw-bg-[#1279BE] focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-green-500 ${!selectedSurvey && "tw-opacity-50 tw-cursor-not-allowed"
                }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Copy size={20} className="tw-mr-2" />
              Preview
            </motion.fieldset>
            <motion.fieldset
              onClick={handleCopy}
              disabled={!selectedSurvey}
              className={`tw-w-full tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-[#03335b] hover:tw-bg-[#1279BE] focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 ${!selectedSurvey && "tw-opacity-50 tw-cursor-not-allowed"
                }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Copy size={20} className="tw-mr-2" />
              Create Copy
            </motion.fieldset>
          </div>
        </div>
      </motion.div>
      <motion.div
        className="tw-mt-8 tw-text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <span
          onClick={() => navigate("/feedback360/home")}
          className="tw-inline-flex tw-cursor-pointer tw-items-center tw-text-base tw-text-[#03335b] hover:tw-text-[#03335b]/70"
        >
          <ArrowLeft size={20} className="tw-mr-2" />
          Back to Home
        </span>
      </motion.div>
      {preview && previewData &&
        <SurveyPreview
          survey={previewData}
          onClose={() => setPreview(false)}
          title={previewData.title || `Survey Title ${surveyNumber}`}
          logo={previewData.logo || SurveyLogo}
          selectedLanguages={selectedLanguageOptions} />
      }
    </div>
  );
};

export default CopySurveyPage;
