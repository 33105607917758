import React from 'react'
import SurveyBuilder from './FormBuilders/SurveyBuilder'
import { Container } from '@mui/material'

const CreateSurveyForm = () => {
  return (
    <>
        <SurveyBuilder />
    </>
  )
}

export default CreateSurveyForm