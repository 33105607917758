import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts3D from 'highcharts/highcharts-3d';
import { ChartPropsTS } from '../../../utils/SurveyFormData';
import { Box, useTheme } from '@mui/material';

// Initialize the 3D module
Highcharts3D(Highcharts);

const MatrixChart: React.FC<ChartPropsTS> = ({ data_matrix, data_rows, data_columns, title, type, question_type }) => {
    const theme = useTheme();

    const options: Highcharts.Options = {
        chart: {
            type: 'bar',
            options3d: {
                enabled: true,
                alpha: 0,
                beta: 0,
                depth: 80,
                viewDistance: 5
            },
            marginRight: 10, // Increase right margin to prevent cutting off
            height: (8 * 50) + 200,
            style: {
                fontFamily: theme.typography.fontFamily
            }
        },
        title: {
            text: '',
            style: {
                fontSize: '18px',
                fontWeight: 'bold'
            }
        },
        xAxis: {
            categories: data_rows,
            title: {
                text: null
            },
            labels: {
                style: {
                    color: theme.palette.text.primary,
                    fontSize: '12px'
                },
                autoRotation: [-45, -90], // Allow label rotation to prevent overlap
                overflow: 'justify'
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            title: {
                text: 'Percentage',
                align: 'high'
            },
            labels: {
                overflow: 'justify',
                format: '{value}%',
                style: {
                    color: theme.palette.text.primary,
                    fontSize: '12px'
                }
            }
        },
        tooltip: {
            formatter: function (this: Highcharts.TooltipFormatterContextObject) {
                const point = this.point as Highcharts.Point & { count: number };
                return `<b>${this.x}</b><br/>${this.series.name}: <b>${point.y}%</b> (${point.count})`;
            },
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.divider,
            borderRadius: 8,
            style: {
                color: theme.palette.text.primary
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                depth: 5,
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        textOutline: 'none',
                        fontWeight: 'normal',
                        color: theme.palette.text.primary,
                        fontSize: '11px'
                    }
                }
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            shadow: false,
            itemStyle: {
                color: theme.palette.text.primary
            }
        },
        credits: {
            enabled: false
        },
        colors: [
            '#4e79a7', '#f28e2c', '#e15759', '#76b7b2', '#59a14f',
            '#edc949', '#af7aa1', '#ff9da7', '#9c755f', '#bab0ab'
        ],
        series: data_matrix?.map(series => ({
            type: 'bar',
            name: series.name,
            data: series.data.map((value, index) => ({
                y: series.data_percentage[index],
                count: value
            }))
        })) as Highcharts.SeriesOptionsType[]
    };

    return (
        <Box sx={{
            m: 2,
            p: 2,
            boxShadow: 3,
            borderRadius: 2,
            bgcolor: 'background.paper',
            '& .highcharts-container': {
                width: '100% !important',
            }
        }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </Box>
    );
};

export default MatrixChart;