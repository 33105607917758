import React from 'react';
import './AppointmentBookingDashboard.css';

function AppointmentBookingDashboard() {


  return (
    <>
      <section className='ReportsPrograms'>
            <div className="container-fluid px-0 mb-3 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md">
                        <h1 className="main-title">Appointment Booking Dashboard
                        </h1>
                    </div>
                </div>

                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://appcdn.goqii.com/storeimg/67834_1730788986.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-3">The Appointment Booking Dashboard is a streamlined platform for scheduling and managing appointments. It offers features like real-time availability, automated reminders, and easy integration with calendars, ensuring a hassle-free experience for both clients and providers.</p>
                        </div>
                    </div>
                </div>


                <div className="row">

                       <div className="col-md-12 mb-3">
                            <div className="dashboard-graph-box mb-3">
                              <h1 className='dashboard-graph-title '>
                                    <span>Customer Details</span> 
                                </h1>
                              <div className='row'>
                                  <div className='col-4 d-flex'>
                                    <div className="form-group" style={{ width: "100%" }}>
                                        <input
                                            type="text"
                                            placeholder="Search by Customer"
                                            className="form-control input-field-ui ps-5"
                                        />
                                        <img src={require("../../images/icon-search.png")} className="input-icon-seacrh" alt="" />
                                    </div>
                                </div>
                                <div class="col-6 px-0">
                              <button
                                type="button"
                                id="xlsxSubmit"
                                class="btn btn-success uplaod-file-btn"
                              >
                                Search Customer
                              </button>
                            </div>
                              </div>

                              <div className='row'>

                              <div class="col-md-4">
                                <div class="userslistSearch my-2" style={{borderRadius:"10px"}}>
                                  <a class="name" href="/members/VHNBS3ROQlhjN0huU1ZjZVZya082QT09"
                                    ><div class="row">
                                      <div class="col-4 col-md-auto">
                                        <img
                                          src="https://appcdn.goqii.com/user/user/l_1437644704.png"
                                          alt="Abdul Rahman Al Zahrani"
                                          class="profile-img"
                                        />
                                      </div>
                                      <div class="col col-md ps-0">
                                        <a class="name" href="/members/VHNBS3ROQlhjN0huU1ZjZVZya082QT09"
                                          >Abdul Rahman Al Zahrani</a
                                        ><br />
                                        <h5 class="text-small">
                                          <span class="">Email: johndoe@gmail.com</span>
                                        </h5>
                                        <h5 class="text-small">
                                          <span class="">Phone: 1234567890</span>
                                        </h5>
                                      </div>
                                    </div></a>
                                </div>
                            </div>
                            


                              </div>

                              <div className='row'>
                              <div className='col-12 mt-4'>
                                <hr />
                                </div>
                                <div className='col-12 mt-4'>
                                  <h1 className='dashboard-graph-title '>
                                      <span>Book a New Appointment</span> 
                                  </h1>
                                </div>
                              </div>
                             

                           
                            <div class="row align-items-center">

                              <div class="col mb-3">
                                <label for="selectRole" class="form-label">Date:</label>
                                <input
                                  type="date"
                                  class="form-control input-field-ui"
                                />
                              </div>

                              <div class="col-auto mb-3">
                                <label for="selectRole" class="form-label">Time:</label>
                                <input
                                  type="time"
                                  class="form-control input-field-ui"
                                />
                              </div>

                              <div class="col mb-3">
                                <label for="selectRole" class="form-label">Service Provider:</label>
                                <select class="form-select input-field-ui" >
                                    <option value="blocker">Provider 1</option>
                                    <option value="major">Provider 2</option>
                                    <option value="minor">Provider 3</option>
                                    <option value="niceToHave">Provider 4</option>
                                </select>
                              </div>

                              <div class="col mb-3">
                                <label for="selectRole" class="form-label">Service Offers:</label>
                                <select class="form-select input-field-ui" >
                                    <option value="blocker">Blood Test</option>
                                    <option value="major">Physiotherapy</option>
                                </select>
                              </div>

                              
                              <div class="col-12">
                                <button
                                  type="button"
                                  id="xlsxSubmit"
                                  class="btn btn-success uplaod-file-btn"
                                >
                                  Book Appointment
                                </button>
                              </div>
                              
                          </div>


                            </div>
                        </div>


                        <div className="col-md-12 mb-3">
                            <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                    <span>Appointment List</span> 
                                </h1>
                               <div className='table-responsive mdp-appointment-table'>
                               <table class="table table-striped mb-0">
                               <thead>
                                <tr>
                                    <th>Customer Name</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Provider</th>
                                    <th>Service</th>
                                    <th>Status</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>John Doe</td>
                                    <td>2024-11-06</td>
                                    <td>16:18	</td>
                                    <td>Provider 1</td>
                                    <td>Physiotherapy</td>
                                    <td>
                                        <select className='form-select input-field-ui-table'>
                                            <option value="Pending">Pending</option>
                                            <option value="Confirmed">Confirmed</option>
                                            <option value="Completed">Completed</option>
                                        </select>
                                    </td>
                                    <td>
                                            <textarea
                                              type="text"
                                              name="title"
                                              placeholder="Enter Remarks"
                                              rows="2"
                                              class="form-control input-field-ui-table"
                                          ></textarea>
                                  </td>
                                </tr>
                                <tr>
                                    <td>John Doe</td>
                                    <td>2024-11-06</td>
                                    <td>16:18	</td>
                                    <td>Provider 1</td>
                                    <td>Physiotherapy</td>
                                    <td>
                                        <select className='form-select input-field-ui-table'>
                                            <option value="Pending">Pending</option>
                                            <option value="Confirmed">Confirmed</option>
                                            <option value="Completed">Completed</option>
                                        </select>
                                    </td>
                                    <td>
                                            <textarea
                                              type="text"
                                              name="title"
                                              placeholder="Enter Remarks"
                                              rows="2"
                                              class="form-control input-field-ui-table"
                                          ></textarea>
                                  </td>
                                </tr>
                            </tbody>
                                </table>
                               </div>

                            </div>
                        </div>


                </div>
        </div>
      </section>
    </>
  );
}

export default AppointmentBookingDashboard;
