import { PointOptionsObject } from "highcharts";
import { SelectOptionType } from "./CommunicationFormData";
import { SliderProps } from "@mui/material";

export interface ResponsesResponseTS {
    question_id: number,
    response_text: any | string | null,
    choice_id: number | null,
    matrix_row_options_id: number | null;
    matrix_column_options_id: number | null;
    rating_value: number | null;
    slider_value: number | null;
    nps_value: number | null;
    csat_value: number | null;
};

export interface PagesResponseTS {
    page_id: number,
    page_number: number,
    responses: ResponsesResponseTS[]
};

export interface SurveyFormResponseTS {
    user_id: number | null,
    survey_id: number | null,
    language_code: string | null,
    pages: PagesResponseTS[]
};

//-----xxx-----//

export interface ChoicesTS {
    id: number,
    choice_text: string,
    is_other: number,
    translations?: TranslationTS[],
};

export interface QuestionTypeTS {
    id: number,
    type_name: string,
    type_label: string
};

export interface QuestionOptionTS {
    id: number,
    validation: string,
    message: string,
    translations?: TranslationTS[],
};

export interface MatrixRowOptionTS {
    id: number,
    row_option_text: string,
    translations?: TranslationTS[],
};

export interface MatrixColumnOptionTS {
    id: number,
    column_option_text: string,
    translations?: TranslationTS[],
};

export interface SliderOptionTS {
    // id: number,
    left_side_label: string,
    right_side_label: string,
    min_value: number,
    max_value: number,
    step_size_value: number,
    translations?: TranslationTS[],
};

export interface RatingOptionTS {
    // id: number,
    scale: number,
    shape: string,
    color: string,
    labels: {
        [index: string]: string
    },
    translations?: TranslationTS[],
}

export interface QuestionsDataTS {
    id: number,
    question_text: string,
    order: number,
    translations?: TranslationTS[],
    question_type: QuestionTypeTS,
    question_options: QuestionOptionTS[] | [],
    choices: ChoicesTS[] | [],
    matrix_row_options: MatrixRowOptionTS[] | [],
    matrix_column_options: MatrixColumnOptionTS[] | [],
    slider_option: SliderOptionTS,
    rating_option: RatingOptionTS
};

export interface PagesDataTS {
    id: number,
    title: string,
    description: string,
    page_number: number,
    translations?: TranslationTS[],
    questions: QuestionsDataTS[]
};

export interface SurveyFormDataTS {
    id: number,
    title: string,
    description: string,
    logo?: string,
    translations?: TranslationTS[],
    pages: PagesDataTS[]
}

export interface SurveyFormCommonProps {
    order: number,
    question_text: string,
    page_id: number,
    question_type: string,
    responseValue?: any,
    onResponseChange?: (response: Partial<ResponsesResponseTS>, response_checkbox?: Partial<ResponsesResponseTS>[]) => void;
    question_options?: any,
    error?: string,
    disabled?: boolean,
    currentSelectedLanguage?: SelectOptionType[],
    currentLanguageDirection?: string,
    pageResponses?: PagesResponseTS
}

export interface InputTypeProps extends SurveyFormCommonProps {
    // page_id: number,
    // question_text: string;
    // order: number;
    // responseValue?: any,
    // onResponseChange: (response: Partial<ResponsesResponseTS>, response_checkbox?: Partial<ResponsesResponseTS>[]) => void;
    // error?: string
}

export interface ChoicesTypeProps extends InputTypeProps {
    choices: ChoicesTS[];
}

export interface MatrixRatingProps extends InputTypeProps {
    matrix_row_options: MatrixRowOptionTS[];
    matrix_column_options: MatrixColumnOptionTS[];
    isMobile?: boolean;
    // onSave: (data: { [key: string]: string }) => void;
}

export interface SliderOptionProps extends InputTypeProps {
    slider_option: SliderOptionTS;
}

export interface RatingOptionProps extends InputTypeProps {
    rating_option: RatingOptionTS;
}

export interface OtherTextAreaProps {
    id: number
    handleOtherTextChange: (id: number, text: string) => void,
    otherTextAreaRef: (ref: HTMLTextAreaElement) => void,
    value: string,
    disabled?: boolean,
}

//-----xxx-----//

export interface QuestionsDataBuilderTS {
    id?: number;
    question_text: string;
    order: number;
    translations?: TranslationTS[];
    question_type: {
        id: number;
        type_name: string;
    };
    question_options: QuestionOptionBuilderTS[];
    choices: ChoicesBuilderTS[];
    matrix_row_options: MatrixRowOptionBuilderTS[];
    matrix_column_options: MatrixColumnOptionBuilderTS[];
    // slider_option?: SliderOptionTS | {};
    // rating_option?: RatingOptionTS | {};
    slider_option?: SliderOptionBuilderTS | any;
    rating_option?: RatingOptionBuilderTS | any;
}

export interface PagesDataBuilderTS {
    id?: number,
    title: string,
    description: string,
    page_number: number,
    translations?: TranslationTS[],
    questions: QuestionsDataBuilderTS[],
};

export interface SurveyFormDataBuilderTS {
    id?: number,
    logo?: string,
    user_id: number,
    platform: string,
    survey_type: string,
    template_name: string,
    title: string,
    description: string,
    translations?: TranslationTS[],
    pages: PagesDataBuilderTS[],
}

export interface TranslationTS {
    language_code: string;
    id?: number;
    [key: string]: string | number | undefined;
}

export interface PageBuilderPropsTS {
    page: PagesDataBuilderTS;
    updatePage: (page: PagesDataBuilderTS) => void;
    removePage: () => void,
    getTotalQuestions: () => number,
    // recalculateOrder: (page: PagesDataBuilderTS[]) => void;
}

export interface QuestionBuilderPropsTS {
    question: QuestionsDataBuilderTS;
    updateQuestion: (question: QuestionsDataBuilderTS) => void;
    removeQuestion: () => void;
    // setIsPreview: (isPreview: boolean) => void;
    togglePreview: () => void;
}

export interface ChoicesBuilderTS {
    id?: number,
    choice_text: string,
    is_other: number,
    question_choice_logic: QuestionChoiceLogicTS | any;
    translations?: TranslationTS[],
};

export interface QuestionOptionBuilderTS {
    id?: number,
    validation: string,
    message: string,
    translations?: TranslationTS[],
};

export interface MatrixRowOptionBuilderTS {
    id?: number,
    row_option_text: string,
    translations?: TranslationTS[],
};

export interface MatrixColumnOptionBuilderTS {
    id?: number,
    column_option_text: string,
    translations?: TranslationTS[],
};

export interface SliderOptionBuilderTS {
    id?: number,
    left_side_label: string,
    right_side_label: string,
    min_value: number,
    max_value: number,
    step_size_value: number,
    translations?: TranslationTS[],
};

export interface RatingOptionBuilderTS {
    id?: number,
    scale: number,
    shape: string,
    color: string,
    labels: {
        [index: string]: string
    },
    translations?: TranslationTS[],
}

export interface QuestionChoiceLogicTS {
    question_id?: number;
    choice_id?: number;
    skip_to: string;
}

export const languageOptions: SelectOptionType[] = [
    { value: 'en', label: 'English' },
    { value: 'ar', label: 'Arabic' },
];

export interface PageOption {
    logic_id: number;
    page_number: number;
    label: string;
    value: string;
    disabled?: boolean;
}

export interface ExtendedSurveyFormDataBuilderTS extends SurveyFormDataBuilderTS {
    logo?: string;
}
export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export interface TabData {
    label: string;
    value: string;
}

//-----xxx-----//

export interface SingleSurvey {
    id: number,
    title: string,
    description: string,
    status: string,
    user_id: number,
    responses_count: number,
    survey_analysis_type: string,
    avg_csat_value: number,
    avg_rating: number
    promoters: number,
    passives: number,
    detractors: number,
    nps_score: number,
    shape: string,
    scale: number,
    color: string
    languages: string,
}

//-----xxx-----//
export interface CopySurveyBuilderPayloadTS {
    id: number,
    user_id: number,
}

//-----xxx-----//
export interface CardElementsTS {
    card_image?: string;
    card_title: string;
    card_body: string;
}

export interface AlertElementsTS {
    type: string;
    title: string;
    message: string;
}

//-----xxx-----//

export interface SurveyPreviewProps {
    survey: SurveyFormDataBuilderTS;
    onClose: () => void;
    title: string;
    logo: string;
    selectedLanguages: SelectOptionType[];
}

//-----xxx-----//

export interface RatingOption {
    scale: number;
    color: string;
}

export type RatingOptionShape = 'heart' | 'smiley' | 'thumb' | 'star';

export interface RTLRatingProps {
    max: number;
    value: number;
    onChange: (event: React.SyntheticEvent<Element, Event>, value: number | null) => void;
    ratingOptionShape: RatingOptionShape;
    rating_option: RatingOption;
    currentLanguage: string;
}

//-----xxx-----//

export interface AdaptiveSliderProps extends Omit<SliderProps, 'value' | 'onChange'> {
    value: number;
    onChange: (event: Event, value: number | number[]) => void;
    min: number;
    max: number;
    currentLanguage: string;
}

//-----xxx-----//

export interface AnalyzeSurveyPayloadTS {
    survey_id: number;
}

export interface AnalyzeSurveyChoices {
    username?: string;
    name?: string;
    response_text?: string;
    choice_text?: string;
    // y?: number;
    slider_value?: number;
    min_value?: number;
    max_value?: number;
    rating_value?: number;
    scale?: number;
    row_option_text?: string;
    column_option_text?: string;
    count_value?: number;
    count_percentage?: number;
    created_at?: string;
    user_id?: number;
}

export interface AnalyzeSurveyResponseTS {
    order: number;
    question_text: string;
    type_name: string;
    choices: AnalyzeSurveyChoices[]
    detractors?: number;
    passives?: number;
    promoters?: number;
    nps_score?: number;
    avg_rating?: number;
    min_value?: number;
    max_value?: number;
    avg_range?: number;
    avg_csat_value?: number;
    shape: string;
    scale: number;
    color: string;

}

export interface AnalyzeSurveyMetricsResponseTS {
    only_visitors: number;
    completion_rate: number;
    avg_response_time: number;
    respondents: number;
    title: string;
    description: string;
}

export interface SingleSurveyResponsePayloadTS {
    survey_id: number;
    user_id: number;
}

export interface SingleSurveyResponsePropTS {
    user_id?: number;
}

export interface SingleSurveyResponseMetricsTS {
    user_id: number;
    start_time: string;
    end_time: string;
    time_taken: string;
    language_code: string;
    responses: SingleSurveyResponseTS[];
}

export interface SingleSurveyResponseTS {
    question_id: number;
    order: number;
    question_text: string;
    translated_text: string;
    question_type: string;
    answers: SingleSurveyResponseAnswerTS[];
}

export interface SingleSurveyResponseAnswerTS {
    translated_text: string;
    reference_type: string;
    column_name: string;
    matrix_answers?: {
        translated_text: string;
        reference_type: string;
        column_name: string;
    }[];
}

//-----xxx-----//

export interface ChartPropsTS {
    data?: { name: string; y: number; y_percentage: number; min_value?: number, max_value?: number }[]; // y_percentage: custom not as per highcharts docs
    data_matrix?: { name: string, data: number[]; data_percentage: number[] }[]; // y_percentage: custom not as per highcharts docs
    data_rows?: [];
    data_columns?: [];
    title: string;
    type: 'pie' | 'bar' | 'gauge';
    question_type: string;
}

export interface GaugeChartPropsTS {
    npsScore: number;
}

export interface CustomPointOptionsObject extends PointOptionsObject {
    y_percentage?: number;
}

export interface AllSurveysPayloadTS {
    platform: string;
    filter?: string;
}

export const SurveyHomeMenuOptions = [
    'Edit survey',
    'Make a copy',
]

//-----xxx-----//

export interface SaveResponseEntryTS {
    id?: number | undefined;
    survey_id: number;
    user_id: number;
    start_time?: string | undefined;
    end_time?: string | undefined;
}