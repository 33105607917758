import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Container, Button, Tabs, Tab, IconButton, useMediaQuery, useTheme, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DevicesIcon from '@mui/icons-material/Devices';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import LaptopIcon from '@mui/icons-material/Laptop';
import {
  SurveyFormDataBuilderTS,
  QuestionsDataBuilderTS,
  SurveyFormCommonProps,
  ResponsesResponseTS,
  ChoicesTS,
  SliderOptionTS,
  MatrixRowOptionTS,
  MatrixColumnOptionTS,
  RatingOptionTS,
  SurveyPreviewProps
} from '../../../utils/SurveyFormData';
import InputBox from '../FormElements/InputBox';
import RadioBox from '../FormElements/RadioBox';
import CheckBox from '../FormElements/CheckBox';
import TextAreaBox from '../FormElements/TextAreaBox';
import NPSBox from '../FormElements/NPSBox';
import SliderBox from '../FormElements/SliderBox';
import MatrixRatingBox from '../FormElements/MatrixRatingBox';
import RatingBox from '../FormElements/RatingBox';
import CSATBox from '../FormElements/CSATBox';
import { SelectOptionType } from '../../../utils/CommunicationFormData';
import Select from 'react-dropdown-select';

const SurveyPreview: React.FC<SurveyPreviewProps> = ({ survey, onClose, title, logo, selectedLanguages }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [deviceType, setDeviceType] = useState('desktop');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isMobileScreen, setIsMobileScreen] = useState(false)

  const [selectedLanguageOptions, setSelectedLanguageOptions] = useState<SelectOptionType[]>(selectedLanguages)
  const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState<SelectOptionType[]>([selectedLanguages[0]])
  const [currentLanguageDirection, setCurrentLanguageDirection] = useState("en")

  console.log(survey);

  useEffect(() => {
    const initialResponses: { [key: number]: ResponsesResponseTS } = {};
    survey.pages.forEach(page => {
      page.questions.forEach(question => {
        if (question.id !== undefined) {
          initialResponses[question.id] = {
            question_id: question.id,
            response_text: null,
            choice_id: null,
            matrix_row_options_id: null,
            matrix_column_options_id: null,
            rating_value: null,
            slider_value: null,
            nps_value: null,
            csat_value: null,
          };
        }
      });
    });
    // setResponses(initialResponses);
  }, [survey]);

  useEffect(() => {
    if (currentSelectedLanguage.length > 0) {
      setCurrentLanguageDirection(currentSelectedLanguage[0].value === "ar" ? "rtl" : "ltr");
    }
  }, [currentSelectedLanguage])

  //   const handleResponseChange = (questionId: number, response: Partial<ResponsesResponseTS>, response_checkbox?: Partial<ResponsesResponseTS>[]) => {
  //     setResponses(prev => ({
  //       ...prev,
  //       [questionId]: { ...prev[questionId], ...response },
  //     }));
  //   };

  const renderQuestionComponent = (question: QuestionsDataBuilderTS) => {
    // if (question.id === undefined) return null; // Early exit if question is invalid

    const commonProps: SurveyFormCommonProps = {
      order: question.order,
      // question_text: question.question_text,
      question_text: question.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "question_text")?.translated_text?.toString() ?? "",
      page_id: currentPage + 1,
      question_type: question.question_type?.type_name || 'unknown', // Fallback in case type_name is undefined
      question_options: question.question_options,
      disabled: true,
      currentSelectedLanguage: currentSelectedLanguage
    };

    switch (question.question_type?.type_name) {
      case 'input':
        return <InputBox {...commonProps} />;
      case 'radio':
        return <RadioBox {...commonProps} choices={question.choices as ChoicesTS[]} />;
      case 'checkbox':
        return <CheckBox {...commonProps} choices={question.choices as ChoicesTS[]} />;
      case 'comment_box':
        return <TextAreaBox {...commonProps} />;
      case 'nps':
        return <NPSBox {...commonProps} />;
      case 'range':
        return <SliderBox {...commonProps} slider_option={question.slider_option as SliderOptionTS} />;
      case 'matrix_rating':
        return (
          <MatrixRatingBox
            {...commonProps}
            matrix_row_options={question.matrix_row_options as MatrixRowOptionTS[]}
            matrix_column_options={question.matrix_column_options as MatrixColumnOptionTS[]}
            isMobile={isMobileScreen}
          />
        );
      case 'rating':
        return <RatingBox {...commonProps} rating_option={question.rating_option as RatingOptionTS} />;
      case 'csat_smiley':
        return <CSATBox {...commonProps} rating_option={question.rating_option as RatingOptionTS} question_text={question.question_text} />;
      default:
        console.warn('Unsupported question type:', question.question_type?.type_name); // Logging for debugging
        return null;
    }
  };


  const getDeviceClass = () => {
    switch (deviceType) {
      case 'mobile':
        return 'tw-w-full tw-max-w-sm';
      case 'tablet':
        return 'tw-w-full tw-max-w-md';
      default:
        return 'tw-w-full tw-max-w-3xl';
    }
  };

  const handleLanguageChange = (selected: SelectOptionType[]) => {
    // setTimeout(() => {
    setCurrentSelectedLanguage(selected);
    // }, 500);

  };

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50 tw-p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className={`tw-bg-[#f1f6fc] tw-absolute tw-top-9 tw-rounded-xl tw-shadow-2xl ${isMobile ? 'tw-w-full tw-h-full' : 'tw-w-8/12 tw-h-[80vh]'} tw-overflow-hidden tw-flex tw-flex-col`}
      >
        <div className="tw-flex tw-justify-end tw-items-center tw-p-4 tw-bg-[#03335b] tw-text-white">
          {/* <h1 className="text-2xl font-bold truncate">{title}</h1> */}
          <div className="tw-flex tw-items-center">
            <Tabs
              value={deviceType}
              onChange={(_, newValue) => setDeviceType(() => {
                if (newValue === 'mobile' || newValue === 'tablet') {
                  setIsMobileScreen(true);
                } else {
                  setIsMobileScreen(false);
                }
                return newValue;
              })}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="device type tabs"
            >
              <Tab icon={<LaptopIcon />} value="desktop" aria-label="desktop" />
              <Tab icon={<TabletMacIcon />} value="tablet" aria-label="tablet" />
              <Tab icon={<PhoneAndroidIcon />} value="mobile" aria-label="mobile" />
            </Tabs>
            <IconButton onClick={onClose} color="inherit" className="tw-ml-2">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="tw-flex-grow tw-overflow-auto tw-p-4">
          <div className={`tw-mx-auto ${getDeviceClass()}`}>
            <div className="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6 tw-mb-4">
              <h2 className="tw-text-xl tw-font-semibold tw-mb-2">Preview</h2>
              <p className="tw-text-sm tw-text-gray-600 tw-mb-4">This is how your survey will appear on a {deviceType} device.</p>
              <div className="tw-flex tw-items-center tw-justify-center tw-bg-gray-200 tw-p-2 tw-rounded">
                <DevicesIcon className="mr-2" />
                <span className="tw-text-sm tw-font-medium">{deviceType.charAt(0).toUpperCase() + deviceType.slice(1)} View</span>
              </div>
            </div>
            <Container maxWidth="md" className="tw-bg-white tw-rounded-lg tw-p-6">
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="baseline"
                mb="20px"
              >
                {selectedLanguageOptions.length > 1 ? (
                  <>
                    <Typography
                      variant='subtitle1'
                      mr={1}
                    >
                      <strong>Language:</strong>
                    </Typography>
                    <Select
                      placeholder='Select Language...'
                      options={selectedLanguageOptions}
                      values={currentSelectedLanguage}
                      onChange={handleLanguageChange}
                      searchable={false}
                      style={{
                        width: "200px",
                        textAlign: "left",
                        background: "#ffffff",
                        fontSize: "16px",
                      }}
                    />
                  </>
                ) : (
                  <Typography
                    variant='subtitle1'
                  >
                    {/* <strong>Language:</strong> {currentSelectedLanguage.length === 1 && currentSelectedLanguage[0].label} */}
                  </Typography>
                )}
              </Box>
              <div className="tw-mb-6 tw-text-center">
                {survey && survey.logo &&
                  <img src={survey.logo || ""} alt="Survey Logo" className="tw-mx-auto tw-w-24 tw-h-24 tw-object-contain tw-mb-4" />
                }
                {/* <h2 className="text-2xl font-bold text-indigo-800">{title}</h2> */}
                <h2 className="tw-text-2xl tw-font-bold tw-text-black">{survey.translations?.find(translation => translation.language_code === currentSelectedLanguage[0].value)?.translated_text}</h2>
              </div>
              <form>
                <AnimatePresence mode="wait">
                  <motion.div
                    key={currentPage}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    {survey.pages[currentPage].questions.map((question, index) => (
                      <motion.div
                        key={index}
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: index * 0.1 }}
                        className="tw-mb-6 tw-p-4 tw-bg-white tw-rounded-lg tw-shadow"
                        dir={currentLanguageDirection}
                      >
                        {renderQuestionComponent(question)}
                      </motion.div>
                    ))}
                  </motion.div>
                </AnimatePresence>
                <motion.div
                  className={survey.pages.length > 1 ? "tw-flex tw-justify-between tw-items-center tw-mt-8" : "tw-flex tw-justify-center tw-items-center tw-mt-8"}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.5 }}
                >
                  {survey.pages.length > 1 && <Button
                    variant="contained"
                    sx={{ backgroundColor: "#03335b"}}
                    onClick={() => setCurrentPage(prev => Math.max(0, prev - 1))}
                    disabled={currentPage === 0}
                  >
                    Previous
                  </Button>}
                  <span className="tw-text-sm tw-text-gray-600">
                    Page {currentPage + 1} of {survey.pages.length}
                  </span>
                  {survey.pages.length > 1 && <Button
                    variant="contained"
                    sx={{ backgroundColor: "#03335b"}}
                    onClick={() => setCurrentPage(prev => Math.min(survey.pages.length - 1, prev + 1))}
                    disabled={currentPage === survey.pages.length - 1}
                  >
                    Next
                  </Button>}
                </motion.div>
              </form>
            </Container>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default SurveyPreview;