import { Box, FormHelperText, InputLabel, OutlinedInput } from '@mui/material'
import React, { useState } from 'react'
import { InputTypeProps } from '../../../utils/SurveyFormData'
import FormErrorBox from './FormErrorBox';

const InputBox = ({ page_id, question_text, order, responseValue, onResponseChange, question_options, error, disabled, currentSelectedLanguage }: InputTypeProps) => {
  const [value, setValue] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (onResponseChange) {
      onResponseChange({ response_text: e.target.value });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      sx={{
        "& > :not(style)": { m: 1 },
        m: 1,
        p: 1,
        direction: disabled && currentSelectedLanguage && currentSelectedLanguage[0].value === "ar" ? "rtl" : "unset",
      }}
    >
      {error && <FormErrorBox error={error} />}
      <InputLabel
        htmlFor={"input_" + page_id + "_" + order}
        sx={{ whiteSpace: "wrap", textAlign: "left", color: "black"  }}
      >
        {question_options?.some(
          (data: any) => data.validation === "required"
        ) && "* "}
        {order + ") "}
        {question_text}
      </InputLabel>
      {disabled ? (
        <OutlinedInput
          fullWidth
          id={"input_" + page_id + "_" + order}
          aria-describedby="my-helper-text"
          sx={{ boxSizing: "border-box" }}
          name={"input_" + page_id + "_" + order}
          disabled={disabled}
        //  onChange={handleChange}
        //  value={responseValue?.response_text || ""}
        />
      ) : (
        <OutlinedInput
          fullWidth
          id={"input_" + page_id + "_" + order}
          aria-describedby="my-helper-text"
          sx={{ boxSizing: "border-box" }}
          name={"input_" + page_id + "_" + order}
          onChange={handleChange}
          value={responseValue?.response_text || ""}
        />
      )
      }

    </Box>
  );
}

export default InputBox