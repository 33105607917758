import React from 'react';
import './LoyaltyProgram.css';


function LoyaltyProgram() {

  return (
    <>
      <section className='LoyaltyProgram'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Loyalty Program</h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-new-template">Create New Template</Link></div> */}
          </div>

          <div className='loyalty-program mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://appcdn.goqii.com/storeimg/93913_1724653484.jpg" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Our customizable platform offers a seamless, high-performance loyalty solution tailored to drive member engagement and satisfaction. It integrates with popular health apps, supports dynamic health challenges, and includes localized rewards, creating a holistic and rewarding experience. With adaptable UI/UX and offline rewards integration, the platform enhances brand presence and loyalty offerings.</p>
              </div>
            </div>
          </div>

          <div className='loyalty-program p-3'>
            <div className="row mb-4">
                <div className="col-md-6">
                    <label className="form-label sub-title">Name of the Program</label>
                    <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Name of the Program"/>
                </div>
            </div>


            <div className='row mb-4'>
                <div className="col-md-3">
                            <div className="mb-2">
                            <label className="form-check-label sub-title">Is the loyalty program tier-based?</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" name="templateRadio" id="templateRadio1" type="radio" value="None" defaultChecked/>
                                <label htmlFor='templateRadio1'  className="form-check-label pt-1">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" name="templateRadio" id="templateRadio2" type="radio" value="Text"/>
                                    <label htmlFor='templateRadio2'  className="form-check-label pt-1">No</label>
                            </div>
                </div>

                <div className="col-md-3">
                    <label htmlFor='nameProgram' className="form-label sub-title">Number of Tiers</label>
                    <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Number"/>
                </div>

            </div>

            <div className="row mb-3">
                <div className="col-md-6">
                <button type="button" id="startProcess" className="btn btn-success uplaod-file-btn">Create Program</button>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                <hr className='mb-4' />
                <div className="table-responsive">
                <table className="table table-striped mb-0">
                    <thead className='table-dark '>
                        <tr>
                        <th scope="col">Tier Name</th>
                        <th scope="col">Points</th>
                        <th scope="col">Rewards</th>
                        <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            {/* <td>
                             Tier Name 1
                            </td> */}
                            <td>
                             Bronze
                            </td>
                            <td>
                                200
                            </td>
                            <td>
                                1000
                            </td>
                            <td>
                                <button className="btn btn-Edit">Edit</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>

                        <tr>
                            {/* <td>
                             Tier Name 2
                            </td> */}
                            <td>
                             Silver
                            </td>
                            <td>
                                300
                            </td>
                            <td>
                                5000
                            </td>
                            <td>
                                <button className="btn btn-Edit">Edit</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <input type="text" className="form-control input-field-ui" placeholder="Enter Tier Name"/>
                            </td>
                            <td>
                                <input type="text" className="form-control input-field-ui" placeholder="Enter Points"/>
                            </td>
                            <td>
                                <input type="text" className="form-control input-field-ui" placeholder="Enter Rewards"/>
                            </td>
                            <td>
                                <button className="btn btn-Edit">Save</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <input type="text" className="form-control input-field-ui" placeholder="Enter Tier Name"/>
                            </td>
                            <td>
                                <input type="text" className="form-control input-field-ui" placeholder="Enter Points"/>
                            </td>
                            <td>
                                <input type="text" className="form-control input-field-ui" placeholder="Enter Rewards"/>
                            </td>
                            <td>
                                <button className="btn btn-Edit">Save</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>


                        <tr>
                            <td colSpan={4} className='text-center'>
                                <button type='button' className="btn link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><i className="bi bi-plus-circle"></i> Add Tier</button>
                            </td>
                        </tr>

                    </tbody>
                    </table>
                    </div>
                    
                </div>
               
            </div>

            
          </div>
        </div>
      </section>
    </>
  );
}

export default LoyaltyProgram;
