import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { languageOptions, SingleSurveyResponseMetricsTS, SingleSurveyResponsePropTS, SingleSurveyResponseTS } from '../../utils/SurveyFormData';
import { fetchSingleSurveyResponse } from '../../services/SurveyFormService';
import { motion } from 'framer-motion';
import { Box, Button, Container, Divider, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import moment from 'moment';

const SingleSurveyResponse = () => {

    const { surveyId, userId } = useParams();
    const [responseData, setResponseData] = useState<SingleSurveyResponseMetricsTS | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [searchFilter, setSearchFilter] = useState<string>("")

    useEffect(() => {
        if (userId) {
            fetchUserResponse(userId);
        }
    }, [userId])

    const fetchUserResponse = async (userId: string) => {

        setIsLoading(true);

        const payload = {
            survey_id: surveyId ? parseInt(surveyId) : 0,
            user_id: userId ? parseInt(userId) : 0
        }

        try {
            const res = await fetchSingleSurveyResponse(payload);
            if (res.data.statusCode === 200) {
                setResponseData({
                    user_id: res.data.response.user_id,
                    start_time: res.data.response.start_time,
                    end_time: res.data.response.end_time,
                    time_taken: res.data.response.time_taken,
                    language_code: res.data.response.language_code,
                    responses: res.data.response.responses,
                });
            } else {
                setResponseData(null);

                console.log(res.data.message);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleSearchUserResponse = () => {
        fetchUserResponse(searchFilter);
    }


    return (
        <div className='tw-text-base md:tw-min-h-[80vh] tw-min-h-screen'>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                sx={{
                    "& > :not(style)": { m: 1 },
                    m: 2,
                    p: 2,
                }}
            >
                <InputLabel
                    htmlFor={"input_"}
                    sx={{ whiteSpace: "wrap", textAlign: "left" }}
                >
                    Search any user's response:
                </InputLabel>
                <Box
                    sx={{
                        "& > :not(style)": { mr: 1 },
                        // m: 2,
                        // p: 2,
                    }}
                >
                    <OutlinedInput
                        id={"input_search"}
                        aria-describedby="my-helper-text"
                        sx={{ boxSizing: "border-box" }}
                        name={"input_search"}
                        placeholder='User ID...'
                        endAdornment={
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        }
                        onChange={(e) => setSearchFilter(e.target.value)}
                        value={searchFilter || ""}
                    />
                    <Button
                        variant='contained'
                        onClick={handleSearchUserResponse}
                    >
                        Search
                    </Button>
                </Box>
            </Box>
            {isLoading ? (
                <div className="tw-flex tw-items-center tw-justify-center tw-h-screen">
                    <motion.div
                        className="tw-w-16 tw-h-16 tw-border-t-4 tw-border-blue-500 tw-border-solid tw-rounded-full"
                        animate={{ rotate: 360 }}
                        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                    />
                    <motion.div
                        className="tw-absolute tw-text-xl tw-font-semibold tw-text-blue-500"
                        initial={{ opacity: 0.5, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
                    >
                        Loading...
                    </motion.div>
                </div>
            ) : responseData && responseData.user_id ? (
                <Container className="tw-py-8">
                    <Box
                        mb={2}
                        sx={{
                            backgroundColor: "white",
                            padding: "15px",
                            border: "1px solid lightgray",
                            borderRadius: "10px",
                        }}
                    >
                        <Box
                            display='flex'
                            alignItems='baseline'
                        >
                            <Typography
                                variant='h6'
                                sx={{
                                    minWidth: '150px',
                                    textAlign: 'start',
                                }}
                            >
                                <strong>Collector: </strong>
                            </Typography>
                            <Typography
                                variant='subtitle1'
                                textAlign='start'
                            >
                                {responseData.user_id}
                            </Typography>
                        </Box>
                        <Box
                            display='flex'
                            alignItems='baseline'
                        >
                            <Typography
                                variant='h6'
                                sx={{
                                    minWidth: '150px',
                                    textAlign: 'start',
                                }}
                            >
                                <strong>Started:</strong>
                            </Typography>
                            <Typography
                                variant='subtitle1'
                                textAlign='start'
                            >
                                {moment(responseData.start_time).format('dddd, MMMM DD YYYY, h:mm:ss a')}
                            </Typography>
                        </Box>
                        <Box
                            display='flex'
                            alignItems='baseline'
                        >
                            <Typography
                                variant='h6'
                                sx={{
                                    minWidth: '150px',
                                    textAlign: 'start',
                                }}
                            >
                                <strong>Modified: </strong>
                            </Typography>
                            <Typography
                                variant='subtitle1'
                                textAlign='start'
                            >
                                {moment(responseData.end_time).format('dddd, MMMM DD YYYY, h:mm:ss a')}
                            </Typography>
                        </Box>
                        <Box
                            display='flex'
                            alignItems='baseline'
                        >
                            <Typography
                                variant='h6'
                                sx={{
                                    minWidth: '150px',
                                    textAlign: 'start',
                                }}
                            >
                                <strong>Time Spent: </strong>
                            </Typography>
                            <Typography
                                variant='subtitle1'
                                textAlign='start'
                            >
                                {responseData.time_taken + " mins"}
                            </Typography>
                        </Box>
                        <Box
                            display='flex'
                            alignItems='baseline'
                        >
                            <Typography
                                variant='h6'
                                sx={{
                                    minWidth: '150px',
                                    textAlign: 'start',
                                }}
                            >
                                <strong>Language: </strong>
                            </Typography>
                            <Typography
                                variant='subtitle1'
                                textAlign='start'
                            >
                                {languageOptions.find(l => l.value === responseData.language_code)?.label}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        mb={2}
                        sx={{
                            backgroundColor: "white",
                            padding: "15px",
                            border: "1px solid lightgray",
                            borderRadius: "10px",
                        }}
                    >
                        {responseData.responses.map((val, i) => {
                            return (
                                <Box
                                    key={i}
                                    mb={3}
                                // display="flex"
                                // flexDirection="column"
                                // alignItems="flex-start"
                                >
                                    <Typography
                                        variant='h6'
                                        textAlign='left'
                                    >
                                        <strong>{'Q' + val.order + ')'}</strong><br /> {val.translated_text}
                                    </Typography>
                                    <Divider />
                                    {val.answers.map((val1, i1) => {
                                        let npsCat = "";
                                        let csatCat = "";
                                        if (val.question_type === 'nps') {
                                            const npsValue = parseInt(val1.translated_text)
                                            if (npsValue >= 0 && npsValue <= 6) {
                                                npsCat = "Detractors";
                                            } else if (npsValue >= 7 && npsValue <= 8) {
                                                npsCat = "Passives";
                                            } else if (npsValue >= 9 && npsValue <= 10) {
                                                npsCat = "Promoters";
                                            }
                                        } else if (val.question_type === 'csat_smiley') {
                                            if (responseData.language_code === 'en') {
                                                csatCat = [
                                                    { level: 1, label: 'Very Unsatisfied' },
                                                    { level: 2, label: 'Unsatisfied' },
                                                    { level: 3, label: 'Neutral' },
                                                    { level: 4, label: 'Satisfied' },
                                                    { level: 5, label: 'Very Satisfied' }
                                                ][parseInt(val1.translated_text) - 1].label;
                                            } else if (responseData.language_code === 'ar') {
                                                csatCat = [
                                                    { level: 1, label: 'غير راضٍ جدًا' },
                                                    { level: 2, label: 'غير راض' },
                                                    { level: 3, label: 'حيادي' },
                                                    { level: 4, label: 'راضي' },
                                                    { level: 5, label: 'راض جدا' }
                                                ][parseInt(val1.translated_text) - 1].label;
                                            }

                                        }

                                        return (
                                            val.question_type === 'matrix_rating' ? (
                                                <Box
                                                    key={i1}
                                                    display='flex'
                                                >
                                                    <Typography
                                                        key={val1.translated_text + i1}
                                                        variant='subtitle1'
                                                        textAlign='left'
                                                    >
                                                        {val1.translated_text + ": " + (val1.matrix_answers && val1.matrix_answers[0]?.translated_text)}
                                                    </Typography>
                                                </Box>
                                            ) : val.question_type === 'nps' ? (
                                                <Typography
                                                    key={val1.translated_text + i1
                                                    }
                                                    variant='subtitle1'
                                                    textAlign='left'
                                                >
                                                    {val1.translated_text + ` (${npsCat})`}
                                                </Typography>
                                            ) : val.question_type === 'csat_smiley' ? (
                                                <Typography
                                                    key={val1.translated_text + i1
                                                    }
                                                    variant='subtitle1'
                                                    textAlign='left'
                                                >
                                                    {csatCat}
                                                </Typography>
                                            ) : val.question_type === 'range' || val.question_type === 'rating' ? (
                                                <Typography
                                                    key={val1.translated_text + i1
                                                    }
                                                    variant='subtitle1'
                                                    textAlign='left'
                                                >
                                                    {"Value: " + val1.translated_text}
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    key={val1.translated_text + i1
                                                    }
                                                    variant='subtitle1'
                                                    textAlign='left'
                                                >
                                                    {val1.translated_text}
                                                </Typography>
                                            )
                                        );
                                    })}
                                </Box>
                            );
                        })}
                    </Box>
                </Container>
            ) : (
                <p className='tw-text-center'>No user response found.</p>
            )
            }
        </div >
    )
}

export default SingleSurveyResponse