import { Box, Checkbox, FormControlLabel, FormGroup, FormHelperText, InputLabel } from '@mui/material'
import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react'
import { ChoicesTypeProps, ResponsesResponseTS } from '../../../utils/SurveyFormData'
import OtherTextAreaBox from './OtherTextAreaBox';
import FormErrorBox from './FormErrorBox';

const CheckBox = ({ question_text, order, choices, onResponseChange, question_options, error, disabled, currentSelectedLanguage }: ChoicesTypeProps) => {

    const localData = JSON.parse(localStorage.getItem("checkbox_values") || '{}');

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<{ [key: number]: boolean }>({});
    const [checkboxResponses, setCheckboxResponses] = useState<Partial<ResponsesResponseTS>[]>([]);
    const [otherText, setOtherText] = useState<{ [key: number]: string }>({});
    const otherTextAreaRefs = useRef<{ [key: number]: HTMLTextAreaElement | null }>({});

    const handleCheckboxChange = (id: number, isOther: number) => {
        setSelectedCheckboxes(prevSelectedChoices => {
            const newSelectedChoices = { ...prevSelectedChoices, [id]: !prevSelectedChoices[id] };

            if (!newSelectedChoices[id] && isOther) {
                setOtherText(prevOtherText => {
                    const newOtherText = { ...prevOtherText, [id]: '' };
                    updateResponses(newSelectedChoices, newOtherText);
                    return newOtherText;
                });
            } else {
                updateResponses(newSelectedChoices, otherText);
            }

            localStorage.setItem("checkbox_values", JSON.stringify({ ...localData, [id]: !prevSelectedChoices[id] }));

            if (isOther && newSelectedChoices[id]) {
                otherTextAreaRefs.current[id]?.focus();
            }

            return newSelectedChoices;
        });
    };

    const handleOtherTextChange = (id: number, text: string) => {
        setOtherText(prevOtherText => {
            const newOtherText = { ...prevOtherText, [id]: text };
            updateResponses(selectedCheckboxes, newOtherText);
            return newOtherText;
        });

        localStorage.setItem("checkbox_values", JSON.stringify({ ...localData, [id]: text }));
    };

    const updateResponses = (selectedChoices: { [key: number]: boolean }, otherTexts: { [key: number]: string }) => {
        const updatedResponse: Partial<ResponsesResponseTS>[] = Object.keys(selectedChoices)
            .filter(choiceId => selectedChoices[parseInt(choiceId)])
            .map(choiceId => ({
                choice_id: parseInt(choiceId),
                response_text: otherTexts[parseInt(choiceId)] || null
            }));
        setCheckboxResponses(updatedResponse);
    };

    useEffect(() => {
        // checkboxResponses.forEach(response => {            
        //     onResponseChange(response);
        // });
        if (onResponseChange) {
            onResponseChange({}, checkboxResponses);
        }
    }, [checkboxResponses]);

    return (
        <Box display='flex' flexDirection='column' alignItems="flex-start" sx={{
            '& > :not(style)': { m: 1 }, m: 1, p: 1, direction: disabled && currentSelectedLanguage && currentSelectedLanguage[0].value === "ar" ? "rtl" : "unset",
        }}>
            {error && <FormErrorBox error={error} />}
            <InputLabel htmlFor="demo-radio-buttons-group-label" sx={{ whiteSpace: "wrap", textAlign: "left", color: "black" }}>{question_options?.some((data: any) => data.validation === 'required') && "* "}{order + ") "}{question_text}</InputLabel>
            <FormGroup>
                {choices.map((val, i) => {
                    const choiceText = currentSelectedLanguage ? val.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "choice_text")?.translated_text?.toString() : "";
                    return (
                        <Fragment key={i}>
                            {disabled ? (
                                <FormControlLabel
                                    control={
                                        // <Checkbox name={val.choice_text} disabled={disabled} />
                                        <Checkbox name={choiceText} disabled={disabled} />
                                    }
                                    // label={val.choice_text}
                                    label={choiceText}
                                />
                            ) : (
                                <FormControlLabel sx={{textAlign: "left"}}
                                    control={
                                        // <Checkbox name={val.choice_text} checked={!!selectedCheckboxes[val.id] || !!localData[val.id]} onChange={() => handleCheckboxChange(val.id, val.is_other)} />
                                        <Checkbox name={choiceText} checked={!!selectedCheckboxes[val.id] || !!localData[val.id]} onChange={() => handleCheckboxChange(val.id, val.is_other)} />
                                    }
                                    // label={val.choice_text}
                                    label={choiceText}
                                />
                            )}
                            {/* {(val.is_other) ? <OtherTextAreaBox id={val.id} handleOtherTextChange={handleOtherTextChange} otherTextAreaRef={(ref) => (otherTextAreaRefs.current[val.id] = ref)} value={localData[val.id] || otherText[val.id] || ''} /> : null} */}
                            {(val.is_other) ? <OtherTextAreaBox id={val.id} handleOtherTextChange={handleOtherTextChange} otherTextAreaRef={(ref) => (otherTextAreaRefs.current[val.id] = ref)} value={otherText[val.id] || ''} /> : null}
                        </Fragment>
                    )
                })}
            </FormGroup>
        </Box>
    )
}

export default CheckBox