// MyContext.js
import React, { createContext, ReactNode, useState } from 'react';
// import { SurveyContextProps, SurveyContextState } from '../utils/ContextData';
import { SurveyContextProps } from '../utils/ContextData';
import { SurveyFormDataBuilderTS } from '../utils/SurveyFormData';
import { SelectOptionType } from '../utils/CommunicationFormData';
import { UserFormDataTS } from '../utils/AuthenticationData';

// Create the context
const AppContext = createContext<SurveyContextProps | undefined>(undefined);

// Create a provider component
const AppContextProvider = ({ children } : { children: ReactNode }) => {
  const [survey, setSurvey] = useState<SurveyFormDataBuilderTS>({
        // id: 1,
        user_id: 1,
        logo: '',
        platform: '',
        survey_type: '',
        template_name: '',
        title: '',
        description: '',
        translations: [],
        pages: [
            {
                title: '',
                description: '',
                page_number: 1,
                translations: [],
                questions: [
                    {
                        question_text: '',
                        order: 1,
                        translations: [],
                        question_type: {
                            id: 2,
                            type_name: 'radio'
                        },
                        question_options: [],
                        choices: [
                            {
                                choice_text: '',
                                is_other: 0,
                                question_choice_logic: {},
                            },
                            {
                                choice_text: '',
                                is_other: 0,
                                question_choice_logic: {},
                            }
                        ],
                        matrix_row_options: [],
                        matrix_column_options: [],
                        slider_option: {},
                        rating_option: {},
                    }
                ]
            }
        ]
  });

  const [isDisabledLogicsButton, setIsDisabledLogicsButton] = useState(true)

  const [selectedLanguageOptions, setSelectedLanguageOptions] = useState<SelectOptionType[]>([]);

  const [selectedTab, setSelectedTab] = useState(0);

  const [userDetails, setUserDetails] = useState<Partial<UserFormDataTS>>({
    id: 0,
    username: "",
    email: ""
  })

  const contextProps: SurveyContextProps = {
    survey,
    setSurvey,
    isDisabledLogicsButton,
    setIsDisabledLogicsButton,
    selectedLanguageOptions,
    setSelectedLanguageOptions,
    selectedTab,
    setSelectedTab,
    userDetails,
    setUserDetails
  }

  return (
    <AppContext.Provider value={{ ...contextProps }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
