import React, { useEffect, useState } from "react";
import { SingleSurvey } from "../../utils/SurveyFormData";
import { fetchSurveys } from "../../services/SurveyFormService";
import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Clipboard, AlertCircle } from "lucide-react";

const ChooseTemplateOptions = () => {
  const platform = "goqii";
  const [allSurveys, setAllSurveys] = useState<SingleSurvey[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllSurveys(platform);
  }, []);

  const fetchAllSurveys = async (platform: string) => {
    setIsLoading(true);
    setError(null);
    const payload = {
      platform: platform,
      survey_type: "template",
    };
    try {
      const data = await fetchSurveys(payload);

      if (data.data.status === "success") {
        setAllSurveys(data.data.response);
      } else {
        setError(data.data.message);
      }
    } catch (error) {
      setError("An error occurred while fetching surveys.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  return (
    <div className="tw-min-h-[85vh] tw-px-4">
      <motion.h1
        className="tw-text-3xl md:tw-text-2xl tw-font-bold tw-text-left tw-text-gray-600 tw-mb-4"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Choose a template to begin
      </motion.h1>

      {isLoading ? (
        <Typography align="center">Loading templates...</Typography>
      ) : error ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          style={{ textAlign: "center", color: "red" }}
        >
          <AlertCircle size={48} style={{ marginBottom: "1rem" }} />
          <Typography variant="h6">{error}</Typography>
        </motion.div>
      ) : (
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-2 tw-gap-6"
        >
          {allSurveys.map((survey, index) => (
            <motion.div
              key={survey.id}
              variants={itemVariants}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.95 }}
            >
              <motion.button
                onClick={() =>
                  navigate(`/feedback360/preview-survey-form/${survey.id}/template`)
                }
                className="tw-w-full tw-cursor-pointer tw-bg-white tw-text-gray-500 tw-rounded-lg tw-shadow-lg tw-overflow-hidden tw-transition-all tw-duration-300 tw-ease-in-out"
                style={{ height: "100%" }}
              >
                <div className="tw-p-6 tw-flex tw-flex-col tw-items-center tw-h-full">
                  <div className="tw-flex tw-justify-center tw-mb-4">
                    <Clipboard size={48} />
                  </div>
                  <h2 className="tw-text-xl tw-font-semibold tw-mb-2">{survey.title}</h2>
                  <p className="tw-text-sm tw-opacity-90 tw-flex-grow">
                    {survey.description}
                  </p>
                </div>
              </motion.button>
            </motion.div>
          ))}
        </motion.div>
      )}
    </div>
  );
};

export default ChooseTemplateOptions;
