import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { motion } from "framer-motion";
import { analyzeSurvey, analyzeSurveyMetrics } from "../../services/SurveyFormService";
import {
  AnalyzeSurveyChoices,
  AnalyzeSurveyMetricsResponseTS,
  AnalyzeSurveyResponseTS,
} from "../../utils/SurveyFormData";
import { Box, Container, Typography } from "@mui/material";
import Chart from "./HighCharts/Chart";
import GaugeChart from "./HighCharts/GaugeChart";
import { StarOutlined } from "@mui/icons-material";
import MatrixChart from "./HighCharts/MatrixChart";
import CsatChart from "./HighCharts/CsatChart";
import SA from "../../analysis.png";
import { Info, XCircle } from "lucide-react";
import Rating from "./Rating";

// interface RatingStarProps {
//   rating: number;
//   shape: string;
// }

// const RatingStar: React.FC<RatingStarProps> = ({ rating, shape }) => {
//   const fillPercentage = (rating / 5) * 100;

//   const getShapePath = () => {
//     switch (shape) {
//       case 'star':
//         return 'M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z';
//       case 'heart':
//         return 'M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41 0.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z';
//       case 'thumb':
//         return 'M13.787 3H7v18h6.787a2 2 0 002-2V5a2 2 0 00-2-2zM21 8a2 2 0 00-2-2h-4a2 2 0 00-2 2v12a2 2 0 002 2h4a2 2 0 002-2V8z';
//       case 'smiley':
//         return 'M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm3-9c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z';
//       default:
//         return 'M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z';
//     }
//   };

//   return (
//     <div className="relative w-24 h-24">
//       <motion.svg
//         viewBox="0 0 24 24"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//         className="w-full h-full"
//         initial={{ scale: 0 }}
//         animate={{ scale: 1 }}
//         transition={{ type: "spring", stiffness: 260, damping: 20 }}
//       >
//         <path
//           d={getShapePath()}
//           stroke="#FFC107"
//           strokeWidth="2"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//         <motion.path
//           d={getShapePath()}
//           fill="#FFC107"
//           initial={{ clipPath: `polygon(0 0, 0 0, 0 100%, 0% 100%)` }}
//           animate={{ clipPath: `polygon(0 0, ${fillPercentage}% 0, ${fillPercentage}% 100%, 0% 100%)` }}
//           transition={{ duration: 1, ease: "easeInOut" }}
//         />
//       </motion.svg>
//     </div>
//   );
// };

const Analysis = () => {
  const { id: surveyId } = useParams();
  const [analyzeSurveyResponses, setAnalyzeSurveyResponses] = useState<AnalyzeSurveyResponseTS[]>();
  const [analyzeSurveyMetricsResponses, setAnalyzeSurveyMetricsResponses] = useState<AnalyzeSurveyMetricsResponseTS>();
  const [tooltipVisibleId, setTooltipVisibleId] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const fetchSurveyAnalysis = async (id: any) => {
    const payload = {
      survey_id: id,
    };

    try {
      const data = await analyzeSurvey(payload);
      if (data.data.status === "success") {
        setAnalyzeSurveyResponses(data.data.response);
      } else {
        console.log(data.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSurveyAnalysisMetrcis = async (id: any) => {
    const payload = {
      survey_id: id,
    };

    try {
      const data = await analyzeSurveyMetrics(payload);
      if (data.data.status === "success") {
        setAnalyzeSurveyMetricsResponses(data.data.response);
      } else {
        console.log(data.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSurveyAnalysis(surveyId);
    fetchSurveyAnalysisMetrcis(surveyId);
  }, [surveyId]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  console.log(analyzeSurveyResponses)

  return (
    <div className="tw-pb-16 tw-pr-4">
      <Box sx={{ mb: 4 }}>
        <Typography
          sx={{ color: "#656565", fontWeight: "bold", fontSize: "24px" }}
        >
          Survey Analysis
        </Typography>
      </Box>
      <div className="tw-text-left tw-mx-auto tw-rounded-xl tw-shadow-lg tw-p-4 tw-bg-white tw-w-full tw-mb-6 tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
        <img src={SA} alt="survey" className="tw-w-60" />
        <p className="tw-text-gray-600 tw-text-lg tw-pl-5 tw-flex tw-justify-center tw-items-center">
        The Survey Analysis section offers tools to interpret survey data.
          Users can view aggregated responses via charts, and apply filters to
          understand trends and insights. It includes visualizations, response
          breakdowns, and statistical summaries to help users make data-driven
          decisions.
          
        </p>
      </div>
      <div className="tw-text-left tw-text-base tw-mx-auto tw-rounded-xl tw-shadow-lg tw-relative tw-bg-white tw-w-full tw-mb-6 tw-flex tw-flex-col tw-justify-between">
        <div className="tw-text-3xl tw-font-bold tw-bg-[#d4e6f0] tw-rounded-t-lg tw-w-full tw-text-center tw-py-3 tw-relative">
        <h5 className=" tw-text-[#656565]">{analyzeSurveyMetricsResponses?.title}</h5>
        <Info className="tw-absolute tw-top-0 tw-bottom-0 tw-my-auto tw-right-5 tw-cursor-pointer" onClick={() => setTooltipVisibleId(true)} />
        </div>

        <div className="tw-p-5">
          {/* <h2 className="text-2xl font-semibold mb-2">Survey Description</h2> */}
          <p className="tw-mb-4">{analyzeSurveyMetricsResponses?.description}</p>

          <table className="tw-min-w-full tw-bg-white tw-border tw-border-gray-300 tw-rounded-md">
            <thead>
              <tr>
                <td className="tw-py-2 tw-px-4 tw-border-b tw-font-bold">Delivered Messages</td>
                {/* <td className="py-2 px-4 border-b">Number of users who have been sent the survey</td> */}
                <td className="tw-py-2 tw-px-4 tw-border-b">10,000</td>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td className="tw-py-2 tw-px-4 tw-border-b tw-font-bold">Read Messages</td>
                {/* <td className="py-2 px-4 border-b">Number of users who have read the messages</td> */}
                <td className="tw-py-2 tw-px-4 tw-border-b">8,500</td>
              </tr>
              <tr>
                <td className="tw-py-2 tw-px-4 tw-border-b tw-font-bold">Clicked Link</td>
                {/* <td className="py-2 px-4 border-b">Number of users who started the survey</td> */}
                <td className="tw-py-2 tw-px-4 tw-border-b">6,200</td>
              </tr>
              <tr>
                <td className="tw-py-2 tw-px-4 tw-border-b tw-font-bold">Respondents</td>
                {/* <td className="py-2 px-4 border-b">Number of users who completed the survey</td> */}
                <td className="tw-py-2 tw-px-4 tw-border-b">{analyzeSurveyMetricsResponses?.respondents}</td>
              </tr>
              <tr>
                <td className="tw-py-2 tw-px-4 tw-border-b tw-font-bold">Completion Rate of Survey</td>
                {/* <td className="py-2 px-4 border-b">Percentage (%) of users that have completed the survey</td> */}
                <td className="tw-py-2 tw-px-4 tw-border-b">{analyzeSurveyMetricsResponses?.completion_rate ? analyzeSurveyMetricsResponses?.completion_rate : 0} %</td>
              </tr>
              {/* <tr>
                <td className="py-2 px-4 border-b font-bold">Completion Number of Survey</td>
                <td className="py-2 px-4 border-b">Number of users that have completed the survey</td>
                <td className="py-2 px-4 border-b">5,800</td>
              </tr> */}
              {/* <tr>
                <td className="py-2 px-4 border-b font-bold">Only Visitors</td> */}
              {/* <td className="py-2 px-4 border-b">Number of users who have opened the link of the survey</td> */}
              {/* <td className="py-2 px-4 border-b">400</td>
              </tr> */}
              <tr>
                <td className="tw-py-2 tw-px-4 tw-border-b tw-font-bold">Average Response Time of Survey</td>
                {/* <td className="py-2 px-4 border-b">Time taken to complete the survey</td> */}
                <td className="tw-py-2 tw-px-4 tw-border-b">{analyzeSurveyMetricsResponses?.avg_response_time + " minutes"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {tooltipVisibleId && (
        <div
          // ref={tooltipRef}
          className="tw-fixed tw-inset-0 tw-z-50 tw-overflow-auto tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center"
          onClick={() => setTooltipVisibleId(false)}
        >
          <div
            className="tw-bg-white tw-text-base tw-relative tw-rounded-md tw-shadow-lg tw-z-20 tw-p-6 tw-m-4 tw-max-w-4xl tw-w-full tw-text-left tw-flex tw-flex-row tw-items-center tw-justify-center"
          // onClick={(e) => e.stopPropagation()}
          >
            <XCircle
              className="tw-h-7 tw-w-7 tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer"
              onClick={() => setTooltipVisibleId(false)}
            />
            <div>
              <h2 className="tw-text-3xl tw-font-semibold tw-mb-3">
                Description
              </h2>
              <table className="tw-min-w-full tw-bg-white tw-border tw-border-gray-300 tw-rounded-md">
                <thead>
                  <tr>
                    <td className="tw-py-2 tw-px-4 tw-border-b tw-font-bold">Delivered Messages</td>
                    <td className="tw-py-2 tw-px-4 tw-border-b">Number of users who have been sent the survey</td>
                    {/* <td className="py-2 px-4 border-b">10,000</td> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tw-py-2 tw-px-4 tw-border-b tw-bold">Read Messages</td>
                    <td className="tw-py-2 tw-px-4 tw-border-b">Number of users who have read the messages</td>
                    {/* <td className="py-2 px-4 border-b">8,500</td> */}
                  </tr>
                  <tr>
                    <td className="tw-py-2 tw-px-4 tw-border-b tw-font-bold">Clicked Link</td>
                    <td className="tw-py-2 tw-px-4 tw-border-b">Number of users who started the survey</td>
                    {/* <td className="py-2 px-4 border-b">6,200</td> */}
                  </tr>
                  <tr>
                    <td className="tw-py-2 tw-px-4 tw-border-b tw-font-bold">Respondents</td>
                    <td className="tw-py-2 tw-px-4 tw-border-b">Number of users who completed the survey</td>
                    {/* <td className="py-2 px-4 border-b">5,800</td> */}
                  </tr>
                  <tr>
                    <td className="tw-py-2 tw-px-4 tw-border-b tw-font-bold">Completion Rate of Survey</td>
                    <td className="tw-py-2 tw-px-4 tw-border-b">Percentage (%) of users that have completed the survey</td>
                    {/* <td className="py-2 px-4 border-b">58%</td> */}
                  </tr>
                  {/* <tr>
          <td className="py-2 px-4 border-b font-bold">Completion Number of Survey</td>
          <td className="py-2 px-4 border-b">Number of users that have completed the survey</td>
          <td className="py-2 px-4 border-b">5,800</td>
        </tr> */}
                  {/* <tr>
                    <td className="py-2 px-4 border-b font-bold">Only Visitors</td>
                    <td className="py-2 px-4 border-b">Number of users who have opened the link of the survey</td> */}
                  {/* <td className="py-2 px-4 border-b">400</td> */}
                  {/* </tr> */}
                  <tr>
                    <td className="tw-py-2 tw-px-4 tw-border-b tw-font-bold">Average Response Time of Survey</td>
                    <td className="tw-py-2 tw-px-4 tw-border-b">Time taken to complete the survey</td>
                    {/* <td className="py-2 px-4 border-b">8 minutes</td> */}
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      )}
      <Typography
        variant="subtitle2"
        textAlign="end"
        fontSize="16px"
      >
        <Link 
          to={`/feedback360/user-survey-response/${surveyId}`}
          style={{
            cursor: "pointer",
          }}
        >
          {"Go to Individual Responses >>>"}
        </Link>
      </Typography>
      <motion.div variants={containerVariants} initial="hidden" animate="visible">
        {analyzeSurveyResponses?.map((val, i) => {
          var { rows, columns, seriesData, chartData }: any = [];

          if (val.type_name === "matrix_rating") {
            rows = [
              ...new Set(val.choices?.map((item) => item.row_option_text)),
            ];
            columns = [
              ...new Set(val.choices?.map((item) => item.column_option_text)),
            ];
            seriesData = columns.map((column: any) => ({
              name: column,
              data: val.choices
                ?.filter((item) => item.column_option_text === column)
                .map((item) => item.count_value),
              data_percentage: val.choices
                ?.filter((item) => item.column_option_text === column)
                .map((item) => item.count_percentage),
            }));
          } else if (val.type_name === "range") {
            chartData = [
              {
                name: "Avg. Value",
                y: [val.avg_range],
                min_value: val.min_value,
                max_value: val.max_value,
              },
            ];
          } else if (val.type_name === "csat_smiley") {
            chartData = [
              {
                name: "Avg. Score",
                y: [val.avg_csat_value],
              },
            ];
          } else {
            chartData = val.choices?.map((choice) => ({
              name: choice.choice_text ?? "Unnamed",
              y_percentage: choice.count_value ?? 0,
              y: choice.count_percentage ?? 0,
            }));
          }

          return (
            <motion.div
              key={i}
              variants={itemVariants}
              className="tw-mb-8 tw-bg-white tw-rounded-lg tw-shadow-lg tw-overflow-hidden"
            >
              <Typography className="tw-p-4 tw-bg-[#d4e6f0] tw-text-[#656565] tw-text-lg">
                Q{val.order + ")"}{" "}
                {val.type_name === "nps" &&
                  "How likely is it that you would recommend "}{" "}
                {val.question_text}{" "}
                {val.type_name === "nps" && "to a friend or colleague?"}
              </Typography>
              <div className="tw-p-4">
                {val.type_name === "radio" && (
                  <Chart
                    data={chartData}
                    title=""
                    type="pie"
                    question_type="radio"
                  />
                )}
                {val.type_name === "checkbox" && (
                  <Chart
                    data={chartData}
                    title=""
                    type="bar"
                    question_type="checkbox"
                  />
                )}
                {val.type_name === "nps" && val.nps_score !== undefined && (
                  <GaugeChart npsScore={val.nps_score} />
                )}
                {val.type_name === "rating" && (
                  <div className="tw-text-center">
                    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                      <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Rating rating={val.avg_rating || 0} shape={val.shape} scale={val.scale} color={val.color} />
                      </motion.div>
                      {/* <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                      >
                        <Typography variant="h3" className="mt-2">
                          {val.avg_rating}
                        </Typography>
                      </motion.div> */}
                    </div>
                    {/* <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5, delay: 0.4 }}
                    >
                      <Typography variant="h6" className="text-gray-600 mt-1">
                        Average Rating
                      </Typography>
                    </motion.div> */}
                  </div>
                )}
                {(val.type_name === "input" ||
                  val.type_name === "comment_box") && (
                    <div className="tw-overflow-auto tw-max-h-[50vh]">
                      <table className="tw-min-w-full tw-divide-ytw- divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-tw-uppercase tw-tracking-wider">
                              Date & Time
                            </th>
                            <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                              Response
                            </th>
                            <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="tw-bg-white tw-divide-y tw-divide-gray-200">
                          {val.choices.map((val1, i1) => (
                            <tr key={i1} className="hover:tw-bg-gray-50">
                              <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                                <div className="tw-text-sm tw-text-gray-500 tw-text-left">
                                  {val1.created_at}
                                </div>
                              </td>
                              <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                                <div className="tw-text-sm tw-text-gray-900 tw-text-left">
                                  {val1.response_text}
                                </div>
                              </td>
                              <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-left">
                                <Link
                                  to={`/feedback360/user-survey-response/${surveyId}/${val1.user_id}`}
                                  className="tw-text-blue-600 hover:tw-text-blue-900 "
                                >
                                  View respondent's answers
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                {val.type_name === "matrix_rating" && (
                  <MatrixChart
                    data_matrix={seriesData}
                    data_rows={rows}
                    data_columns={columns}
                    title={`Chart Title: ${val.question_text}`}
                    type="bar"
                    question_type={val.type_name}
                  />
                )}
                {val.type_name === "range" && (
                  <Chart
                    data={chartData}
                    title=""
                    type="bar"
                    question_type={val.type_name}
                  />
                )}
                {val.type_name === "csat_smiley" && (
                  <div className="tw-w-full tw-max-w-[600px] tw-mx-auto tw-p-2">
                    <CsatChart
                      data={chartData}
                      title=""
                      type="gauge"
                      question_type={val.type_name}
                    />
                  </div>
                )}
              </div>
            </motion.div>
          );
        })}
      </motion.div>
    </div>
  );
};

export default Analysis;
