import { Box, Button, Container, Typography } from '@mui/material'
import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { copySurveyBuilder, fetchSurvey, saveResponseEntry, submitSurvey } from '../../services/SurveyFormService';
import { CopySurveyBuilderPayloadTS, languageOptions, ResponsesResponseTS, SaveResponseEntryTS, SurveyFormCommonProps, SurveyFormDataTS, SurveyFormResponseTS } from '../../utils/SurveyFormData';
import { motion, AnimatePresence } from 'framer-motion';
import InputBox from './FormElements/InputBox';
import RadioBox from './FormElements/RadioBox';
import CheckBox from './FormElements/CheckBox';
import TextAreaBox from './FormElements/TextAreaBox';
import SliderBox from './FormElements/SliderBox';
import RatingBox from './FormElements/RatingBox';
import MatrixRatingBox from './FormElements/MatrixRatingBox';
import NPSBox from './FormElements/NPSBox';
import CSATBox from './FormElements/CSATBox';
import moment from 'moment';
import Select from 'react-dropdown-select';
import { SelectOptionType } from '../../utils/CommunicationFormData';

const SurveyForm = () => {

    const { id, userId } = useParams();
    const location = useLocation()
    const preview = location.pathname.includes("preview");
    const previewTemplate = location.pathname.includes("preview") && location.pathname.includes("template");

    const surveyId = id;
    const [isLoading, setIsLoading] = useState(false)
    const [surveyData, setSurveyData] = useState<SurveyFormDataTS | null>()
    const [currentPage, setCurrentPage] = useState<number>(0)
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [validationErrors, setValidationErrors] = useState<{ [key: number]: string }>({});
    const [responseEntryData, setResponseEntryData] = useState<SaveResponseEntryTS>({
        survey_id: 0,
        user_id: 0,
    });
    const hasFetchedData = useRef(false);  // Use a ref to prevent multiple fetches
    const [selectedLanguageOptions, setSelectedLanguageOptions] = useState<SelectOptionType[]>([])
    const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState<SelectOptionType[]>([])
    const [currentLanguageDirection, setCurrentLanguageDirection] = useState("en")

    useEffect(() => {
        if (!hasFetchedData.current) {
            localStorage.clear();
            fetchSurveyFormData(surveyId);
            hasFetchedData.current = true;
        }
    }, [surveyId]);

    useEffect(() => {
        if (currentSelectedLanguage.length > 0) {
            setCurrentLanguageDirection(currentSelectedLanguage[0].value === "ar" ? "rtl" : "ltr");
            setResponses({
                ...responses,
                language_code: currentSelectedLanguage[0].value,
            });
        }
    }, [currentSelectedLanguage])


    const fetchSurveyFormData = async (surveyId: any) => {
        setIsLoading(true)
        try {
            const data = await fetchSurvey(surveyId);

            if (data.data.status === "success") {
                setSurveyData(data.data.response);
                setCurrentPage(data.data.response.pages[0].page_number);

                await saveResponseEntryStart(id ? parseInt(id) : 0, userId ? parseInt(userId) : 0, moment().format('YYYY-MM-DD HH:mm:ss'));
                // setSurveyStartTime((new Date()).toString());

                const fetchedLanguageOptions = data.data.response.translations.map((translation: any) => {
                    const matchedOption = languageOptions.find(option => option.value === translation.language_code);
                    return matchedOption ? matchedOption : null;
                }).filter(Boolean);

                setSelectedLanguageOptions(fetchedLanguageOptions);

                if (fetchedLanguageOptions.length > 0) {
                    setCurrentSelectedLanguage([fetchedLanguageOptions[0]]);
                    setCurrentLanguageDirection(fetchedLanguageOptions[0].value)
                } else {
                    setCurrentSelectedLanguage([{ value: "en", label: "English" }]);
                    setCurrentLanguageDirection("ltr");
                }

                setResponses({
                    // user_id: null,
                    // user_id: 7,
                    user_id: userId ? parseInt(userId) : null,
                    survey_id: data.data.response.id,
                    language_code: fetchedLanguageOptions[0].value,
                    pages: []
                });
            } else {
                console.log(data.data.message);
                alert(data.data.message);
                setCurrentSelectedLanguage([{ value: "en", label: "English" }]);
                setCurrentLanguageDirection("ltr");
            }
            setIsLoading(false)

        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    }

    const saveResponseEntryStart = async (surveyId: number, userId: number, startTime: string) => {
        const payload = {
            survey_id: surveyId,
            user_id: userId,
            start_time: startTime
        };

        try {

            const startResponse = await saveResponseEntry(payload);

            if (startResponse.data.statusCode === 200) {
                setResponseEntryData({
                    id: startResponse.data.response.id,
                    survey_id: startResponse.data.response.survey_id,
                    user_id: startResponse.data.response.user_id,
                    start_time: startResponse.data.response.start_time,
                })
            }

        } catch (error) {
            console.log(error);
        }
    }

    const saveResponseEntryEnd = async (endTime: string) => {
        const payload = {
            id: responseEntryData.id,
            survey_id: responseEntryData.survey_id,
            user_id: responseEntryData.user_id,
            end_time: endTime
        };
        try {

            const endResponse = await saveResponseEntry(payload);

        } catch (error) {
            console.log(error);
        }
    }

    const [responses, setResponses] = useState<SurveyFormResponseTS>({
        user_id: null,
        survey_id: null,
        language_code: null,
        pages: []
    });

    const handleNextPage = () => {
        if (!validatePageResponses(currentPage)) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            return;
        }
        setCurrentPage(prev => prev + 1);
    }

    const handlePrevPage = () => {
        setCurrentPage(prev => prev - 1);
    }

    const handleResponseChange = (pageId: number, pageNumber: number, questionId: number, questionType: string, response: Partial<ResponsesResponseTS>, response_checkbox?: Partial<ResponsesResponseTS>[]) => {
        setResponses(prevResponses => {
            const pageExists = prevResponses.pages.some(page => page.page_id === pageId);

            const updatedPages = pageExists ? prevResponses.pages.map(page => {
                if (page.page_id === pageId) {
                    let updatedResponses;

                    // if (questionType === 'checkbox' || questionType === 'matrix_rating') {
                    //     const existingResponseIndex = (questionType === 'checkbox') ? page.responses.findIndex(res => res.question_id === questionId && res.choice_id === response.choice_id)
                    //     : (questionType === 'matrix_rating') ? page.responses.findIndex(res => res.question_id === questionId && res.matrix_row_options_id === response.matrix_row_options_id) : null;

                    if (questionType === 'matrix_rating') {
                        const existingResponseIndex = (questionType === 'matrix_rating') ? page.responses.findIndex(res => res.question_id === questionId && res.matrix_row_options_id === response.matrix_row_options_id) : null;

                        // if (response.choice_id !== null) {
                        // Add or update response
                        if (existingResponseIndex !== -1) {
                            // Update existing response
                            updatedResponses = page.responses.map((res, index) => index === existingResponseIndex ? { ...res, ...response } : res);
                        } else {
                            // Add new response
                            updatedResponses = [...page.responses, {
                                question_id: questionId,
                                response_text: response.response_text ?? null,
                                choice_id: response.choice_id ?? null,
                                matrix_row_options_id: response.matrix_row_options_id ?? null,
                                matrix_column_options_id: response.matrix_column_options_id ?? null,
                                rating_value: response.rating_value ?? null,
                                slider_value: response.slider_value ?? null,
                                nps_value: response.nps_value ?? null,
                                csat_value: response.csat_value ?? null,
                            }];
                        }
                        // } 
                        // else {
                        //     // Remove response if unchecked
                        //     updatedResponses = page.responses.filter(res => !(res.question_id === questionId && res.choice_id === response.choice_id));
                        // }
                    } else {
                        const responseExists = page.responses.some(res => res.question_id === questionId);
                        updatedResponses = responseExists ? page.responses.map(res => {
                            if (res.question_id === questionId) {
                                const updatedResponse = {
                                    ...res,
                                    ...response,
                                    response_text: response.response_text ?? null,
                                    choice_id: response.choice_id ?? null,
                                    matrix_row_options_id: response.matrix_row_options_id ?? null,
                                    matrix_column_options_id: response.matrix_column_options_id ?? null,
                                    rating_value: response.rating_value ?? null,
                                    slider_value: response.slider_value ?? null,
                                    nps_value: response.nps_value ?? null,
                                    csat_value: response.csat_value ?? null,
                                };
                                if (
                                    (updatedResponse.response_text === null || updatedResponse.response_text === "") &&
                                    updatedResponse.choice_id === null &&
                                    updatedResponse.matrix_row_options_id === null &&
                                    updatedResponse.matrix_column_options_id === null &&
                                    updatedResponse.rating_value === null &&
                                    updatedResponse.slider_value === null &&
                                    updatedResponse.nps_value === null &&
                                    updatedResponse.csat_value === null
                                ) {
                                    return null;  // Mark for removal
                                }
                                return updatedResponse;
                            }
                            return res;
                        }).filter(res => res !== null) as ResponsesResponseTS[]  // Remove null entries
                            : [...page.responses, {
                                question_id: questionId,
                                response_text: response.response_text ?? null,
                                choice_id: response.choice_id ?? null,
                                matrix_row_options_id: response.matrix_row_options_id ?? null,
                                matrix_column_options_id: response.matrix_column_options_id ?? null,
                                rating_value: response.rating_value ?? null,
                                slider_value: response.slider_value ?? null,
                                nps_value: response.nps_value ?? null,
                                csat_value: response.csat_value ?? null,
                            }];
                    }

                    if (questionType === 'checkbox' && response_checkbox) {
                        response_checkbox.forEach(checkboxResponse => {
                            updatedResponses.push({
                                question_id: questionId,
                                response_text: checkboxResponse.response_text ?? null,
                                choice_id: checkboxResponse.choice_id ?? null,
                                matrix_row_options_id: checkboxResponse.matrix_row_options_id ?? null,
                                matrix_column_options_id: checkboxResponse.matrix_column_options_id ?? null,
                                rating_value: checkboxResponse.rating_value ?? null,
                                slider_value: checkboxResponse.slider_value ?? null,
                                nps_value: checkboxResponse.nps_value ?? null,
                                csat_value: response.csat_value ?? null,
                            });
                        });

                        updatedResponses = updatedResponses.filter(res => res.choice_id !== null || res.response_text !== null);
                    }

                    return { ...page, responses: updatedResponses };
                }
                return page;
            }) : [...prevResponses.pages, {
                page_id: pageId,
                page_number: pageNumber,
                responses: [{
                    question_id: questionId,
                    response_text: response.response_text ?? null,
                    choice_id: response.choice_id ?? null,
                    matrix_row_options_id: response.matrix_row_options_id ?? null,
                    matrix_column_options_id: response.matrix_column_options_id ?? null,
                    rating_value: response.rating_value ?? null,
                    slider_value: response.slider_value ?? null,
                    nps_value: response.nps_value ?? null,
                    csat_value: response.csat_value ?? null,
                }]
            }];

            return { ...prevResponses, pages: updatedPages };
        });

        setValidationErrors((prev) => {
            const { [questionId]: _, ...rest } = prev;
            return rest;
        });
    };


    const getResponseValue = (pageId: number, questionId: number) => {
        const page = responses.pages.find(page => page.page_id === pageId);
        if (!page) return null;
        const response = page.responses.find(response => response.question_id === questionId);

        return response ? response : null;
    };


    const submitResponses = async (surveyResponse: SurveyFormResponseTS) => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        try {
            const data = await submitSurvey(surveyResponse);

            if (data.data.status === "success") {
                await saveResponseEntryEnd(moment().format('YYYY-MM-DD HH:mm:ss'));
                // console.log(JSON.stringify(surveyResponse));
                localStorage.clear();
                // alert(data.data.message);
                // setTimeout(() => {
                //     navigate("/");
                // }, 1000);
                navigate("/feedback360/thank-you");
            } else {
                console.log(data.data.message);
                alert(data.data.message);
                navigate("/feedback360/home");
            }

        } catch (error: any) {
            console.log(error.response.data.message);

        } finally {
            setIsSubmitting(false)
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        // await submitResponses(responses);

        if (!validatePageResponses(currentPage)) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            return;
        }

        if (!userId) {
            alert("User ID cannot be empty, please re-visit the link");
            return;
        }

        await submitResponses(responses);
    };

    const validatePageResponses = (pageNumber: number): boolean => {
        const page = surveyData?.pages.find(p => p.page_number === pageNumber);
        const errors: { [key: number]: string } = {};

        if (!page) return true;

        page.questions.forEach(question => {
            const response = getResponseValue(page.id, question.id);

            question.question_options.forEach(option => {
                if (option.validation === 'required' && (!response || response.response_text === "" ||
                    (
                        question.question_type.type_name === 'matrix_rating' &&
                        responses.pages.find(p => p.page_number === pageNumber)?.responses.filter(resp => resp.question_id === question.id).length !== question.matrix_row_options.length
                    )
                )) {
                    // errors[question.id] = option.message;
                    errors[question.id] = option.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "message")?.translated_text?.toString() ?? "";
                }
            })
        });

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleCopySurvey = async (surveyId: any) => {
        try {
            const payload: CopySurveyBuilderPayloadTS = {
                id: surveyId,
                user_id: 1
            }
            const data = await copySurveyBuilder(payload);

            if (data.data.status === "success") {
                // alert(data.data.message);
                navigate(`/feedback360/create/${data.data.response.id}`);
            } else {
                console.log(data.data.message);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const renderQuestionComponent = (val1: any, key1: any, commonProps: any) => {
        let component: React.ReactNode = null;
        // const error = validationErrors[val1.id];        

        if (val1.question_type.type_name === 'input') {
            component = (
                <div key={key1} className='tw-w-full'>
                    <InputBox {...commonProps} />
                    {/* {error && <p className="error">{error}</p>} */}
                </div>
            );
        } else if (val1.question_type.type_name === 'radio') {
            component = (
                <div key={key1} className='tw-w-full'>
                    <RadioBox {...commonProps} choices={val1.choices} />
                    {/* {error && <p className="error">{error}</p>} */}
                </div>
            );
        } else if (val1.question_type.type_name === 'checkbox') {
            component = (
                <div key={key1} className='tw-w-full'>
                    <CheckBox {...commonProps} choices={val1.choices} />
                    {/* {error && <p className="error">{error}</p>} */}
                </div>
            );
        } else if (val1.question_type.type_name === 'comment_box') {
            component = (
                <div key={key1} className='tw-w-full'>
                    <TextAreaBox {...commonProps} />
                    {/* {error && <p className="error">{error}</p>} */}
                </div>
            );
        } else if (val1.question_type.type_name === 'nps') {
            component = (
                <div key={key1} className='tw-w-full'>
                    <NPSBox {...commonProps} />
                    {/* {error && <p className="error">{error}</p>} */}
                </div>
            );
        } else if (val1.question_type.type_name === 'range') {
            component = (
                <div key={key1} className='tw-w-full'>
                    <SliderBox {...commonProps} slider_option={val1.slider_option} />
                    {/* {error && <p className="error">{error}</p>} */}
                </div>
            );
        } else if (val1.question_type.type_name === 'matrix_rating') {
            component = (
                <div key={key1} className='tw-w-full'>
                    <MatrixRatingBox {...commonProps} matrix_row_options={val1.matrix_row_options} matrix_column_options={val1.matrix_column_options} />
                    {/* {error && <p className="error">{error}</p>} */}
                </div>
            );
        } else if (val1.question_type.type_name === 'rating') {
            component = (
                <div key={key1} className='tw-w-full'>
                    <RatingBox {...commonProps} rating_option={val1.rating_option} />
                    {/* {error && <p className="error">{error}</p>} */}
                </div>
            );
        } else if (val1.question_type.type_name === 'csat_smiley') {
            component = (
                <div key={key1} className='tw-w-full'>
                    <CSATBox {...commonProps} />
                    {/* {error && <p className="error">{error}</p>} */}
                </div>
            );
        }

        return component;
    };

    const handleLanguageChange = (selected: SelectOptionType[]) => {
        setIsLoading(true);

        setTimeout(() => {
            setCurrentSelectedLanguage(selected);
            setValidationErrors({});
            setIsLoading(false);
        }, 500);

        // const updatedTranslations = survey.translations?.filter(translation =>
        //   selected.some(option => option.value === translation.language_code)
        // );

        // setSurvey({
        //   ...survey,
        //   translations: updatedTranslations
        // });
    };

    console.log(surveyData);
    console.log(responses);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='tw-flex tw-justify-center tw-min-h-screen tw-bg-white tw-mb-5 tw-w-full'
        >
            {isLoading ? (
                <div className="tw-flex tw-items-center tw-justify-center tw-h-screen">
                    <motion.div
                        className="tw-w-16 tw-h-16 tw-border-t-4 tw-border-gray-500 tw-border-solid tw-rounded-full"
                        animate={{ rotate: 360 }}
                        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                    />
                    <motion.div
                        className="tw-absolute tw-text-xl tw-font-semibold tw-text-black"
                        initial={{ opacity: 0.5, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
                    >
                        Loading...
                    </motion.div>
                </div>
            ) : (
                <Container maxWidth="lg" className="tw-py-2 tw-px-2 tw-w-full">
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="baseline"
                        mb="10px"
                    >
                        {selectedLanguageOptions.length > 1 ? (
                            <>
                                <Typography
                                    variant='subtitle1'
                                    mr={1}
                                >
                                    <strong>Language: </strong>
                                </Typography>
                                <Select
                                    placeholder='Select Language...'
                                    options={selectedLanguageOptions}
                                    values={currentSelectedLanguage}
                                    onChange={handleLanguageChange}
                                    searchable={false}
                                    style={{
                                        width: "100%",
                                        maxWidth: "200px",
                                        textAlign: "left",
                                        background: "#ffffff",
                                        fontSize: "16px",
                                    }}
                                />
                            </>
                        ) : (
                            <Typography
                                variant='subtitle1'
                                className="tw-text-black"
                                sx={{ padding: "2px 8px", textAlign: "left" }}
                            >
                                {/* <strong>Language:</strong> {currentSelectedLanguage.length === 1 && currentSelectedLanguage[0].label} */}
                            </Typography>
                        )}
                    </Box>
                    <motion.div
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className="tw-bg-white tw-rounded-lg tw-shadow-none md:tw-shadow-lg tw-p-3 tw-mb-4"
                    >
                        {previewTemplate && (
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="tw-bg-gray-500 tw-text-white tw-px-3 tw-py-1 tw-rounded-full tw-mb-3 hover:tw-bg-gray-600 tw-transition tw-duration-300 tw-w-full sm:tw-w-auto tw-text-sm"
                                onClick={() => handleCopySurvey(id)}
                            >
                                Use this template
                            </motion.button>
                        )}
                        {surveyData && surveyData.logo &&
                            <motion.img
                                className="tw-w-36 tw-h-36 tw-mb-2 tw-mx-auto tw-object-contain tw-rounded-md"
                                src={surveyData.logo || ""}
                                alt="Survey Logo"
                            />
                        }
                        <motion.h2
                            className="tw-text-xl sm:tw-text-2xl tw-text-center tw-font-bold tw-text-black tw-mb-2"
                            initial={{ x: -20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: 0.2 }}
                        >
                            {surveyData?.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "title")?.translated_text}
                        </motion.h2>
                        <motion.p
                            className="tw-text-black tw-mb-3 tw-text-sm"
                            initial={{ x: 20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: 0.4 }}
                        >
                            {surveyData?.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "description")?.translated_text}
                        </motion.p>
                    </motion.div>
                    <form dir={currentLanguageDirection} className="tw-bg-white tw-rounded-lg">
                        <AnimatePresence mode="wait">
                            {surveyData?.pages.map((val, key) => {
                                if (val.page_number === currentPage) {
                                    return (
                                        <motion.div
                                            key={key}
                                            initial={{ opacity: 0, x: 50 }}
                                            animate={{ opacity: 1, x: 0 }}
                                            exit={{ opacity: 0, x: -50 }}
                                            transition={{ duration: 0.5 }}
                                        >
                                            {val.questions.map((val1, key1) => {
                                                const error = validationErrors[val1.id];
                                                const commonProps: SurveyFormCommonProps = {
                                                    order: val1.order,
                                                    question_text: val1.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "question_text")?.translated_text?.toString() ?? "",
                                                    page_id: val.id,
                                                    question_type: val1.question_type.type_name,
                                                    responseValue: getResponseValue(val.id, val1.id),
                                                    onResponseChange: (response: Partial<ResponsesResponseTS>, response_checkbox?: Partial<ResponsesResponseTS>[]) => handleResponseChange(val.id, val.page_number, val1.id, val1.question_type.type_name, response, response_checkbox),
                                                    question_options: val1.question_options,
                                                    error: error,
                                                    currentSelectedLanguage: currentSelectedLanguage,
                                                    pageResponses: responses.pages.find(p => p.page_id === val.id)
                                                };

                                                return (
                                                    <motion.div
                                                        key={key1}
                                                        initial={{ y: 20, opacity: 0 }}
                                                        animate={{ y: 0, opacity: 1 }}
                                                        transition={{ delay: key1 * 0.1 }}
                                                        className="tw-mb-4 tw-bg-white tw-rounded-lg tw-shadow-none md:tw-shadow-xl md:tw-ml-0 md:tw-pr-6"
                                                    >
                                                        {renderQuestionComponent(val1, key1, commonProps)}
                                                        {error && (
                                                            <motion.p
                                                                initial={{ opacity: 0 }}
                                                                animate={{ opacity: 1 }}
                                                                className="tw-text-red-500 tw-mt-2 tw-text-sm"
                                                            >
                                                                {error}
                                                            </motion.p>
                                                        )}
                                                    </motion.div>
                                                );
                                            })}
                                        </motion.div>
                                    );
                                }
                            })}
                        </AnimatePresence>
                        <motion.div
                            className="tw-flex tw-flex-col sm:tw-flex-row tw-justify-center tw-mt-4 tw-space-y-2 sm:tw-space-y-0 sm:tw-space-x-2"
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.5 }}
                        >
                            {surveyData && surveyData.pages.length > 1 ? (
                                <>
                                    {currentPage > 1 && <Button variant="contained" onClick={handlePrevPage}>Back</Button>}
                                    {currentPage === surveyData.pages.length ? (
                                        ((!preview) && <Button variant='contained' disabled={isSubmitting} onClick={handleSubmit}>Submit</Button>)
                                    ) : (
                                        <Button variant='contained' type='button' onClick={handleNextPage}>Next</Button>
                                    )}
                                </>
                            ) : surveyData && surveyData.pages.length === 1 ? (
                                ((!preview) && <Button variant='contained' disabled={isSubmitting} onClick={handleSubmit}>Submit</Button>)
                            ) : null
                            }
                        </motion.div>
                    </form>
                </Container>
            )}
        </motion.div>
    )
}

export default SurveyForm