import { Report } from '@mui/icons-material'
import { Box, FormHelperText } from '@mui/material'
import React from 'react'

const FormErrorBox = ({ error }: { error: string }) => {
    return (
        <Box 
        display='flex'
        sx={{
            m: '0px !important'
        }}
        >
            <Report color='error' fontSize='small' />
            <FormHelperText 
            id="component-error-text" 
            error
            sx={{
                ml: '5px',
                mt: '1px'
            }}
            >
                {error}
            </FormHelperText>
        </Box>
    )
}

export default FormErrorBox