import { translateSingleText } from "../services/TranslationService";

export const translateTextToAllLanguages = async (text: string, languages: string) => {
    let textArray = [];
    textArray.push({
        "text": text
    });

    const payload = {
        languages: languages || "",
        texts: textArray,
    }

    try {
        const res = await translateSingleText(payload);

        if (res.data.statusCode === 200) {
            const translatedTexts = res.data.response.reduce((acc: any, { text, to }: { text: string, to: string }) => {
                acc[to] = text;
                return acc;
            }, {});

            return translatedTexts;
        } else {
            console.log(res.data.message);

        }
    } catch (error) {
        console.log(error);

    }
}