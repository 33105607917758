export interface CommunicateFormTS {
    id?: number;
    channel: string;
    sms_name: string;
    trigger_point: string;
    trigger_time: string;
    trigger_interval_time?: string;
    feedback_name: string;
    sms_message: string;
    language?: string;
    status?: string;
}

export type SelectOptionType = {
    value: string;
    label: string;
};

export interface CommunicateFormPayloadTS extends CommunicateFormTS {

}

export const options: SelectOptionType[] = [
    { value: 'Complete Agent Call', label: 'Complete Agent Call' },
    { value: 'Complete Care Navigator Call', label: 'Complete Care Navigator Call' },
];

export const options2: SelectOptionType[] = [
    { value: 'English', label: 'English' },
    { value: 'Arabic', label: 'Arabic' },
];

export const communicationOptions = [
    {
        "name": "SMS",
        "url": "/create-communication"
    },
    {
        "name": "Email",
        "url": "#"
    },
    {
        "name": "Whatsapp",
        "url": "#"
    },
    {
        "name": "In App",
        "url": "#"
    },
]

export interface ManageCommunicationsDataRow {
    sms_name: string;
    trigger_point: string;
    trigger_time: string;
    feedback_name: string;
    language: string;
}

export interface Filter {
    property: string;
    condition: string;
    value: string;
}
export interface CommunicationOption {
    name: string;
    url: string;
    description: string;
    icon: React.ReactNode;
    channel: string;
}

export const triggerOptionsData = ["post-call-care", "post-call-agent", "on-demand", "journey-day"];