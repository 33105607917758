import React, { useRef, useEffect, useState } from 'react';
import { Slider, SliderProps, styled } from '@mui/material';
import { AdaptiveSliderProps } from '../../../utils/SurveyFormData';

const RTLSlider = styled(Slider)(({ theme }) => ({
    transform: 'scaleX(-1)',
    '& .MuiSlider-valueLabel': {
        transform: 'scaleX(-1) translate(50%, -100%)',
    },
}));

const SliderRTL = ({ value, onChange, min, max, currentLanguage, ...props }: AdaptiveSliderProps) => {
    const [isRTL, setIsRTL] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // if (containerRef.current) {
        //     const direction = window.getComputedStyle(containerRef.current).getPropertyValue('direction');            
        //     setIsRTL(direction === 'rtl');
        // }
        if (currentLanguage) {
            setIsRTL(currentLanguage === 'ar' ? true : false);
        }
    }, [currentLanguage]);

    const handleChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            const adjustedValue = isRTL ? max + min - newValue : newValue;
            onChange(event, adjustedValue);
        }
    };

    const SliderComponent = isRTL ? RTLSlider : Slider;

    return (
        <div ref={containerRef} dir={isRTL ? 'rtl' : 'ltr'}>
            <SliderComponent
                value={value}
                onChange={handleChange}
                min={min}
                max={max}
                {...props}
            />
        </div>
    );
};

export default SliderRTL;