import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { InputLabel, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { InputTypeProps } from '../../../utils/SurveyFormData';
import FormErrorBox from './FormErrorBox';

const NPSBox = ({ question_text, order, responseValue, onResponseChange, question_options, error, disabled, currentSelectedLanguage }: InputTypeProps) => {
    const [selectedValue, setSelectedValue] = useState<number | null>(null);
    const [rating, setRating] = useState<number | null>(null);

    const handleRatingChange = (event: React.MouseEvent<HTMLElement>, value: number) => {
        setRating(value);
        if (onResponseChange) {
            onResponseChange({ nps_value: value })
        }
    };

    const currentQuestion1 = currentSelectedLanguage ? currentSelectedLanguage[0].value === "en" ? "How likely is it that you would recommend" : "ما مدى احتمالية أن توصي به" : "";
    const currentQuestion2 = currentSelectedLanguage ? currentSelectedLanguage[0].value === "en" ? "to a friend or colleague?" : "لصديق أو زميل؟" : "";
    const npsLeftLabel = currentSelectedLanguage ? currentSelectedLanguage[0].value === "en" ? "Not at all likely" : "ليس من المرجح على الإطلاق" : "";
    const npsRightLabel = currentSelectedLanguage ? currentSelectedLanguage[0].value === "en" ? "Extremely likely" : "من المرجح للغاية" : "";

    return (
        <Box display='flex' flexDirection='column' alignItems="flex-start" sx={{ '& > :not(style)': { m: 1 }, m: 1, p: 1, direction: disabled && currentSelectedLanguage && currentSelectedLanguage[0].value === "ar" ? "rtl" : "unset", }}>
            {error && <FormErrorBox error={error} />}
            <InputLabel htmlFor="my-input" sx={{ whiteSpace: "wrap", textAlign: "left", color: "black"  }}>{question_options?.some((data: any) => data.validation === 'required') && "* "}{order + ") "}{currentQuestion1} {question_text} {currentQuestion2}</InputLabel>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography variant="caption">{npsLeftLabel}</Typography>
                    <Typography variant="caption">{npsRightLabel}</Typography>
                </Box>
                <Box sx={{ overflowX: 'auto', display: 'flex', justifyContent: 'center' }}>
                    {disabled ? (
                        <ToggleButtonGroup
                            // value={responseValue?.nps_value || rating}
                            exclusive
                            // onChange={handleRatingChange}
                            disabled={disabled}
                            sx={{ display: 'flex', border: '1px solid rgba(0,0,0,0.1)', }}
                            fullWidth
                        >
                            {Array.from({ length: 11 }, (_, index) => (
                                <ToggleButton key={index} value={index} sx={{ minWidth: 20 }}>
                                    {index}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    ) : (
                        <ToggleButtonGroup
                            value={responseValue?.nps_value || rating}
                            exclusive
                            onChange={handleRatingChange}
                            sx={{ display: 'flex', border: '1px solid rgba(0,0,0,0.1)', }}
                            fullWidth
                        >
                            {Array.from({ length: 11 }, (_, index) => (
                                <ToggleButton key={index} value={index} sx={{ minWidth: 20 }}>
                                    {index}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    )}

                </Box>
            </Box>
        </Box>
    );
};

export default NPSBox;
