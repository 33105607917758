import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { GaugeChartPropsTS } from '../../../utils/SurveyFormData';
import SolidGauge from 'highcharts/modules/solid-gauge';
import { Box } from '@mui/material';

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

const GaugeChart: React.FC<GaugeChartPropsTS> = ({ npsScore }) => {
    const options: Highcharts.Options = {
        chart: {
            type: 'solidgauge',
            height: '300px',
            backgroundColor: 'transparent'
        },
        title: {
            text: 'Net Promoter Score',
            style: { fontSize: '18px', fontWeight: 'bold' }
        },
        pane: {
            center: ['50%', '75%'],
            size: '140%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, '#FFF'],
                        [1, '#DDD']
                    ]
                },
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc',
                borderWidth: 0
            } as any
        },
        tooltip: {
            enabled: false
        },
        yAxis: {
            min: -100,
            max: 100,
            stops: [
                [0.1, '#DF5353'], // red
                [0.5, '#DDDF0D'], // yellow
                [0.9, '#55BF3B']  // green
            ],
            lineWidth: 0,
            minorTickInterval: undefined,
            tickAmount: 2,
            title: {
                y: -70,
                text: 'NPS',
                style: { fontSize: '16px' }
            },
            labels: {
                y: 16,
                style: { fontSize: '14px' }
            },
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                },
                linecap: 'square',
                stickyTracking: false,
                rounded: false
            }
        },
        accessibility: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'NPS',
            type: 'solidgauge',
            data: [npsScore],
            dataLabels: {
                format:
                    '<div style="text-align:center">' +
                    '<span style="font-size:30px;font-weight:bold">{y}</span><br/>' +
                    '<span style="font-size:14px;opacity:0.4">NPS</span>' +
                    '</div>'
            },
            tooltip: {
                valueSuffix: ' NPS'
            }
        }],
    };

    return (
        <Box sx={{ m: 2, p: 2, boxShadow: 3, borderRadius: 2, bgcolor: 'background.paper' }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </Box>
    )
}

export default GaugeChart;