import React from 'react';
import './ActivityManagement.css';


function ActivityManagement() {

  return (
    <>
      <section className='ActivityManagement'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Activity Management</h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-new-template">Create New Template</Link></div> */}
          </div>

          <div className='activity-management mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://appcdn.goqii.com/storeimg/69880_1724671949.jpg" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Activity Management allows administrators to set up activities for rewards by assigning categories like Physical, Educational, or Engagement and defining measurement units such as distance, time, or frequency. This structured approach enables flexible and accurate rewards for a variety of user actions, fostering personalized engagement and motivating ongoing participation.</p>
              </div>
            </div>
          </div>

          <div className='activity-management p-3'>
            <div className="row">
                <div className="col-md mb-3">
                    <label className="form-label sub-title">Name of the Activity</label>
                    <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Name of the Activity"/>
                </div>
                <div className="col-md mb-3">
                    <label className="form-label sub-title">Category of the Activity</label>
                    <select className="form-select input-field-ui" aria-label="Default select example">
                        <option value="Activity">Select Activity</option>
                        <option value="Business">Business</option>
                        <option value="Educational">Educational</option>
                        <option value="Physical">Physical</option>
                        <option value="Engagement">Engagement</option>
                    </select>
                </div>
                <div className="col-md mb-3">
                    <label className="form-label sub-title">Select Unit</label>
                    <select className="form-select input-field-ui" aria-label="Default select example">
                    <option value="Unit">Select Unit</option>
                        <option value="miles">Miles</option>
                        <option value="litres">Litres</option>
                        <option value="minutes">Minutes</option>
                        <option value="hours">Hours</option>
                        <option value="lakhs">Lakhs</option>
                        <option value="crores">Crores</option>
                    </select>
                </div>
                
            </div>

            <div className="row mb-3">
                <div className="col-md-6">
                <button type="button" id="startProcess" className="btn btn-success uplaod-file-btn">Create Activity</button>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                <hr className='mb-4' />
                <div className="table-responsive">
                <table className="table table-striped mb-0">
                    <thead className='table-dark '>
                        <tr>
                        <th scope="col">Activity</th>
                        <th scope="col">Category</th>
                        <th scope="col">Unit</th>
                        <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>
                            Videos Watched
                            </td>
                            <td>
                            Engagement
                            </td>
                            <td>
                            kms
                            </td>
                            <td>
                                <button className="btn btn-Edit">Edit</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>

                        <tr>
                            <td>
                            Distance Covered
                            </td>
                            <td>
                            Physical
                            </td>
                            <td>
                            Miles
                            </td>
                            <td>
                                <button className="btn btn-Edit">Edit</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Name of the Activity"/>
                            </td>
                            <td>
                                <select className="form-select input-field-ui" aria-label="Default select example">
                                    <option value="Activity">Select Activity</option>
                                    <option value="Business">Business</option>
                                    <option value="Educational">Educational</option>
                                    <option value="Physical">Physical</option>
                                    <option value="Engagement">Engagement</option>
                                </select>
                            </td>
                            <td>
                                <select className="form-select input-field-ui" aria-label="Default select example">
                                <option value="Unit">Select Unit</option>
                                    <option value="miles">Miles</option>
                                    <option value="litres">Litres</option>
                                    <option value="minutes">Minutes</option>
                                    <option value="hours">Hours</option>
                                    <option value="lakhs">Lakhs</option>
                                    <option value="crores">Crores</option>
                                </select>
                            </td>
                            <td>
                                <button className="btn btn-Edit">Save</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>

                    </tbody>
                    </table>
                    </div>
                    
                </div>
            </div>

            
          </div>
        </div>
      </section>
    </>
  );
}

export default ActivityManagement;
