import { RadioButtonChecked, RadioButtonUnchecked, Report } from '@mui/icons-material'
import { Box, Checkbox, FormControlLabel, FormGroup, FormHelperText, InputLabel } from '@mui/material'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ChoicesTypeProps, ResponsesResponseTS } from '../../../utils/SurveyFormData'
import OtherTextAreaBox from './OtherTextAreaBox'
import FormErrorBox from './FormErrorBox'

const RadioBox = ({ question_text, order, choices, responseValue, onResponseChange, question_options, error, disabled, currentSelectedLanguage }: ChoicesTypeProps) => {

    const [selectedRadio, setSelectedRadio] = useState<number | null>(null)
    const [otherText, setOtherText] = useState<{ [key: number]: string }>({});
    const otherTextAreaRefs = useRef<{ [key: number]: HTMLTextAreaElement | null }>({});

    const handleRadioChange = (id: number, isOther: number) => {
        setSelectedRadio((prevSelected) => {
            let newSelectedRadio = (prevSelected === id) ? null : id;
            // onResponseChange({ choice_id: newSelectedRadio });

            if (isOther) {
                if (newSelectedRadio === null) {
                    setOtherText((prevOtherText) => ({
                        ...prevOtherText,
                        [id]: '',
                    }));
                } else {
                    setOtherText((prevOtherText) => ({
                        ...prevOtherText,
                        [id]: otherText[id] || '',
                    }));
                }
            }

            if (isOther && newSelectedRadio) {
                otherTextAreaRefs.current[id]?.focus();
            }

            return newSelectedRadio;
        });
    };

    const handleOtherTextChange = (id: number, text: string) => {
        setOtherText(prevOtherText => {
            const newOtherText = { ...prevOtherText, [id]: text };
            // onResponseChange({ choice_id: selectedRadio, response_text: newOtherText[id] });
            return newOtherText;
        });

    };

    // useEffect(() => {
    //     if (selectedRadio !== null) {
    //         onResponseChange({ choice_id: selectedRadio });
    //     }
    // }, [selectedRadio]);

    // useEffect(() => {
    //     if (selectedRadio !== null && otherText[selectedRadio]) {
    //         onResponseChange({ choice_id: selectedRadio, response_text: otherText[selectedRadio] });
    //     }
    // }, [otherText]);
    useEffect(() => {
        if (selectedRadio !== null) {
            const response: Partial<ResponsesResponseTS> = { choice_id: selectedRadio };
            if (choices.find((choice) => choice.id === selectedRadio)?.is_other) {
                response.response_text = otherText[selectedRadio] || '';
            }
            if (onResponseChange) onResponseChange(response);
        }
    }, [selectedRadio, otherText]);


    return (
        <Box display='flex' flexDirection='column' alignItems="flex-start" sx={{
            '& > :not(style)': { m: 1 }, m: 1, p: 1, direction: disabled && currentSelectedLanguage && currentSelectedLanguage[0].value === "ar" ? "rtl" : "unset",
        }}>
            {error && <FormErrorBox error={error} />}
            <InputLabel htmlFor="demo-radio-buttons-group-label" sx={{ whiteSpace: "wrap", textAlign: "left", color: "black"  }}>{question_options?.some((data: any) => data.validation === 'required') && "* "}{order + ") "}{question_text}</InputLabel>
            <FormGroup>
                {choices.map((val, i) => {
                    const choiceText = currentSelectedLanguage ? val.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "choice_text")?.translated_text?.toString() : "";
                    return (
                        <Fragment key={i}>
                            <FormControlLabel
                                control={
                                    disabled ? (
                                        // <Checkbox name={val.choice_text} icon={<RadioButtonUnchecked />} checkedIcon={<RadioButtonUnchecked />} disabled={disabled}  />
                                        <Checkbox name={choiceText} icon={<RadioButtonUnchecked />} checkedIcon={<RadioButtonUnchecked />} disabled={disabled} />

                                    ) : (
                                        // <Checkbox name={val.choice_text} icon={<RadioButtonUnchecked />} checkedIcon={<RadioButtonChecked />} checked={(responseValue?.choice_id === val.id) || selectedRadio === val.id} onChange={() => handleRadioChange(val.id, val.is_other)} />
                                        <Checkbox name={choiceText} icon={<RadioButtonUnchecked />} checkedIcon={<RadioButtonChecked />} checked={(responseValue?.choice_id === val.id) || selectedRadio === val.id} onChange={() => handleRadioChange(val.id, val.is_other)} />
                                    )
                                }
                                // label={val.choice_text}
                                label={choiceText}
                                sx={{ whiteSpace: "wrap", textAlign: "left", color: "black" }}
                            />
                            {(val.is_other) ? <OtherTextAreaBox disabled={disabled} id={val.id} handleOtherTextChange={handleOtherTextChange} otherTextAreaRef={(ref) => (otherTextAreaRefs.current[val.id] = ref)} value={otherText[val.id] || ''} /> : null}
                        </Fragment>
                    )
                })}
            </FormGroup>
        </Box>
    )
}

export default RadioBox