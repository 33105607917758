import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { PenTool, Copy, LayoutTemplate } from "lucide-react";

const CreateSurveyOptions = () => {
  const navigate = useNavigate();

  const options = [
    {
      title: "Start from scratch",
      description:
        "Begin with a blank survey or form. Then add your questions, text, and images.",
      icon: <PenTool size={48} />,
      path: "/feedback360/create",
      // color: "from-[#03335b] to-[#03335b]",
    },
    {
      title: "Copy an existing survey",
      description: "Choose a survey. Make a copy. Edit as needed.",
      icon: <Copy size={48} />,
      path: "/feedback360/copy-survey",
      // color: "from-[#03335b] to-[#03335b]",
    },
    {
      title: "Choose a template",
      description:
        "Ask the right questions and save time with a template built for your situation.",
      icon: <LayoutTemplate size={48} />,
      path: "/feedback360/choose-template-options",
      // color: "from-[#03335b] to-[#03335b]",
    },
  ];

  return (
    <div className="tw-min-h-[85vh] tw-mx-auto tw-px-4 tw-pb-8">
      <motion.h1
        className="tw-text-3xl md:tw-text-2xl tw-font-bold tw-text-left tw-text-gray-600 tw-mb-4"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Choose a starting point
      </motion.h1>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-8">
        {options.map((option, index) => (
          <motion.div
            key={option.title}
            className="tw-flex"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <motion.button
              onClick={() => navigate(option.path)}
              className={`tw-w-full tw-bg-white tw-text-gray-600 tw-rounded-lg tw-shadow-lg tw-overflow-hidden tw-transition-transform duration-300 tw-ease-in-out tw-transform hover:tw-scale-105`}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.95 }}
            >
              <div className="tw-p-6">
                <div className="tw-flex tw-justify-center tw-mb-4">{option.icon}</div>
                <h2 className="tw-text-xl tw-font-semibold tw-mb-2">{option.title}</h2>
                <p className="tw-text-sm tw-opacity-90">{option.description}</p>
              </div>
            </motion.button>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default CreateSurveyOptions;
