import instance from "../api/axios"
import { AllSurveysPayloadTS, AnalyzeSurveyPayloadTS, CopySurveyBuilderPayloadTS, SaveResponseEntryTS, SingleSurveyResponsePayloadTS, SurveyFormDataBuilderTS, SurveyFormResponseTS } from "../utils/SurveyFormData";

export const fetchSurvey = (id: Number) => {
    return instance.get(`/fetch-survey/${id}`);
}

export const submitSurvey = (payload: SurveyFormResponseTS) => {
    return instance.post('/submit-survey', payload);
}

export const fetchQuestionTypes = () => {
    return instance.get('/fetch-question-types');
}

export const submitSurveyBuilder = (payload: SurveyFormDataBuilderTS) => {
    return instance.post('/submit-survey-builder', payload);
}

export const fetchSurveys = (payload: AllSurveysPayloadTS) => {
    return instance.post('/surveys', payload);
}

export const analyzeSurvey = (payload: AnalyzeSurveyPayloadTS) => {
    return instance.post('/survey-analysis', payload);
}

export const updateSurveyBuilder = (payload: SurveyFormDataBuilderTS) => {
    return instance.post('/update-survey-builder', payload);
}

export const copySurveyBuilder = (payload: CopySurveyBuilderPayloadTS) => {
    return instance.post('/copy-survey-builder', payload);
}

export const saveResponseEntry = (payload: SaveResponseEntryTS) => {
    return instance.post('/save-response-entry', payload);
}

export const analyzeSurveyMetrics = (payload: AnalyzeSurveyPayloadTS) => {
    return instance.post('/survey-analysis-metrics', payload);
}

export const fetchSingleSurveyResponse = (payload: SingleSurveyResponsePayloadTS) => {
    return instance.post('/fetch-survey-user-response', payload);
}

export const uploadLogo = (payload: FormData) => {
    return instance.post('/upload-file', payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}