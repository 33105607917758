import { Box } from '@mui/material'
import React from 'react'
import { OtherTextAreaProps } from '../../../utils/SurveyFormData';

const OtherTextAreaBox = ({ id, handleOtherTextChange, otherTextAreaRef, value, disabled }: OtherTextAreaProps) => {  

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleOtherTextChange(id, event.target.value);
  }

  return (
    <Box display='flex' flexDirection='column' alignItems="flex-start" sx={{
      '& > :not(style)': { m: 1 },
    }}>
      {/* <InputLabel htmlFor="my-input" sx={{ whiteSpace: "wrap", textAlign: "left" }}>{order+") "}{question_text}</InputLabel> */}
      {disabled ? (
        <textarea className='custom_textarea' cols={30} rows={2} name='demo-textarea' disabled={disabled} />
      ) : (
        <textarea className='custom_textarea' cols={30} rows={2} name='demo-textarea' onChange={handleChange} ref={otherTextAreaRef} value={value} />
      )}
      {/* <FormHelperText id="component-error-text">Error</FormHelperText> */}
    </Box>
  )
}

export default OtherTextAreaBox