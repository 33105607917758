import React, { useEffect, useState, useCallback, ChangeEvent } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Send,
  Calendar,
  MessageSquare,
  Users,
  Eye,
  ChevronDown,
  Plus,
  Minus,
  Mail,
  MessageCircle,
  Smartphone,
  CheckCircle2Icon
} from "lucide-react";
import {
  Box,
  Container,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
  Paper,
  Chip,
  SelectChangeEvent,
  OutlinedInput,
  Modal,
  Fade,
  DialogContent,
  DialogContentText,
  Dialog,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { communicationOptions } from '../../utils/CommunicationFormData';
import { WhatsApp } from "@mui/icons-material";
import {
  CommunicateFormTS,
  CommunicationOption,
  Filter,
  SelectOptionType,
  triggerOptionsData,
} from "../../utils/CommunicationFormData";
import Backdrop from "@mui/material/Backdrop";
import { fetchSurveys } from "../../services/SurveyFormService";
import { createCommunication } from "../../services/CommunicationFormService";
import RDPSelect from "react-dropdown-select"; // alias of Select as Select is default export from this library
import CustomAlertBox from "../SurveyForm/CustomComponents/CustomAlertBox";
import { SingleSurvey, TranslationTS } from "../../utils/SurveyFormData";
import { shortenSingleUrl } from "../../services/UrlShortenService";
import { sendSingleSms } from "../../services/MessageService";

const updatedCommunicationOptions: CommunicationOption[] = [
  {
    name: "SMS",
    url: "/create-communication",
    description: "Send SMS messages",
    icon: <MessageCircle size={24} color="#686868" />,
    channel: "sms",
  },
  // {
  //   name: "Email",
  //   url: "/email",
  //   description: "Send email messages",
  //   icon: <Mail size={24} color="#1565C0" />,
  //   channel: "email",
  // },
  {
    name: "WhatsApp",
    url: "/whatsapp",
    description: "Send WhatsApp messages",
    icon: <WhatsApp fontSize="medium" sx={{ color: "#686868" }} />,
    channel: "whatsapp",
  },
  {
    name: "In-App",
    url: "/in-app",
    description: "Send in-app notifications",
    icon: <Smartphone size={24} color="#686868" />,
    channel: "in-app",
  },
];

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ChooseCommunicationOptions: React.FC = () => {
  const navigate = useNavigate();
  const [openSection, setOpenSection] = useState<string>("channel");
  const [triggerOption, setTriggerOption] = useState<string>("");
  const [whenOption, setWhenOption] = useState<string>("");
  const [whomOption, setWhomOption] = useState<string>("");
  const [journeyDay, setJourneyDay] = useState<string>("1");
  const [customJourneyDay, setCustomJourneyDay] = useState<string>("");
  const [filters, setFilters] = useState<Filter[]>([]);


  const platform = "goqii";
  const [communicateForm, setCommunicateForm] = useState<CommunicateFormTS>({
    channel: "",
    sms_name: "",
    trigger_point: "",
    trigger_time: "",
    trigger_interval_time: "",
    feedback_name: "",
    sms_message:
      "Please take a moment to shared your feedback about the call with {name}. Click here to give feedback - {link}",
    language: "English",
  });
  const [communicateFormErrors, setCommunicateFormErrors] = useState<
    Partial<CommunicateFormTS>
  >({});
  const [feedbackOptions, setFeedbackOptions] = useState<SelectOptionType[]>(
    []
  );
  const [selectedOptions, setSelectedOptions] = useState<SelectOptionType[]>(
    []
  );
  const [selectedFeedbackOptions, setSelectedFeedbackOptions] = useState<
    SelectOptionType[]
  >([]);
  const [selectedOptions2, setSelectedOptions2] = useState<SelectOptionType[]>(
    []
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [openInterval, setOpenInterval] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openSections, setOpenSections] = useState<string[]>(["channel"]);
  const [allSurveys, setAllSurveys] = useState<SingleSurvey[]>([]);

  const [openTestDialog, setOpenTestDialog] = React.useState(false);
  const [mobileTestSMS, setMobileTestSMS] = useState("")
  const [smsTranslations, setSmsTranslations] = useState<TranslationTS[]>([])
  const [mobileTestSMSDisabled, setMobileTestSMSDisabled] = useState(false)

  const handleClickTestDialogOpen = () => {
    if (smsTranslations && smsTranslations.length > 0) {
      const allContainLink = smsTranslations.every(s => {
        return s.sms_message && /{link}/.test(s.sms_message.toString());
      });

      if (!allContainLink) {
        alert("Please add a placeholder {link} in every message.");
        return;
      }
      // else {
      //   setSmsTranslations(smsTranslations.map(s => ({
      //     ...s,
      //     sms_message: s.sms_message && s.sms_message.toString().replace(/{link}/g, "https://survey.goqii.com/survey-form/" + s.id + "/{userid}"),
      //   })))
      // }

      setOpenTestDialog(true);
    }
  };

  console.log(smsTranslations);


  const handleCloseTestDialog = () => {
    setOpenTestDialog(false);
  };

  const toggleSection = useCallback((section: string) => {
    setOpenSection(prev => prev === section ? "" : section);
  }, []);
  const handleTriggerSelect = (trigger: string) => {
    setTriggerOption(trigger);

    setCommunicateForm({
      ...communicateForm,
      trigger_point: trigger,
    });

    setCommunicateFormErrors({
      ...communicateFormErrors,
      trigger_point: "",
    });
  };

  const handleWhenOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWhenOption(event.target.value);

    setCommunicateForm({
      ...communicateForm,
      trigger_time: event.target.value,
    });
    setCommunicateFormErrors({
      ...communicateFormErrors,
      trigger_time: "",
    });
  };

  const handleWhomOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === 'all') setFilters([])
    setWhomOption(event.target.value);
  };

  const handleJourneyDayChange = (event: SelectChangeEvent<string>) => {
    setJourneyDay(event.target.value);
  };

  const addFilter = () => {
    setFilters([...filters, { property: "", condition: "", value: "" }]);
  };

  const removeFilter = (index: number) => {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setFilters(newFilters);
  };

  const updateFilter = (index: number, field: keyof Filter, value: string) => {
    const newFilters = [...filters];
    newFilters[index][field] = value;
    setFilters(newFilters);
  };

  // const handleTriggerPointChange = (selected: SelectOptionType[]) => {
  //   if (selected.length > 0) {
  //     setSelectedOptions(selected);
  //     setCommunicateForm({
  //       ...communicateForm,
  //       trigger_point: selected[0].value,
  //     });

  //     setCommunicateFormErrors({
  //       ...communicateFormErrors,
  //       trigger_point: "",
  //     });
  //   }
  // };

  const handleFeedbackOptionsChange = (selected: SelectOptionType[]) => {
    if (selected.length > 0) {
      setSelectedFeedbackOptions(selected);
      // const link = "https://survey.goqii.com/survey-form/" + selected[0].value + "/{userid}";

      setCommunicateForm((prev) => ({
        ...prev,
        feedback_name: selected[0].label,
        // sms_message: `Please take a moment to shared your feedback about the call with {name}. Click here to give feedback - ${link}`
      }));

      setCommunicateFormErrors({
        ...communicateFormErrors,
        feedback_name: "",
      });

      const feedbackLanguages = allSurveys
        ?.find(s => s.id === parseInt(selected[0]?.value))
        ?.languages?.split(",");

      if (feedbackLanguages) {
        setSmsTranslations(
          feedbackLanguages.map((language: string) => ({
            id: allSurveys?.find(s => s.id === parseInt(selected[0]?.value))?.id,
            language_code: language,
            sms_message: language === "ar"
              ? "يرجى تخصيص بعض الوقت لمشاركة ملاحظاتك حول المكالمة. انقر هنا لتقديم ملاحظاتك - {link}"
              : "Please take a moment to share your feedback about the call. Click here to give feedback - {link}"
          }))
        );
      }
    }
  };

  console.log(smsTranslations);


  // const handleChange2 = (selected: SelectOptionType[]) => {
  //   if (selected.length > 0) {
  //     setSelectedOptions2(selected);
  //     setCommunicateForm({
  //       ...communicateForm,
  //       language: selected[0].value,
  //     });
  //   }
  // };

  // const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const radio = event.target as HTMLInputElement;
  //   if (radio.value === "interval") {
  //     setOpenInterval(true);
  //   } else {
  //     setOpenInterval(false);
  //   }
  //   setCommunicateForm({
  //     ...communicateForm,
  //     trigger_time: radio.value,
  //   });
  //   setCommunicateFormErrors({
  //     ...communicateFormErrors,
  //     trigger_time: "",
  //   });
  // };

  useEffect(() => {
    fetchAllSurveys(platform);
  }, []);

  // const fetchAllSurveys = async (platform: string, filter: string) => {
  const fetchAllSurveys = async (platform: string) => {
    const payload = {
      platform: platform,
      survey_type: "custom",
      // filter: filter
    };
    try {
      const data = await fetchSurveys(payload);

      if (data.data.status === "success") {
        setFeedbackOptions(
          data.data.response.map((data: any) => ({
            value: data.id,
            label: `${data.title} (${data.template_name ? data.template_name : ""
              })`,
          }))
        );
        setAllSurveys(data.data.response);
      } else {
        console.log(data.data.message);
        alert(data.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChanges = (
    e: React.ChangeEvent<HTMLInputElement>,
    input_type: string
  ) => {
    let val = e.target.value;
    if (input_type === "time_interval" && val !== "") {
      if (/^[0-9:]*$/.test(val) && val.length <= 8) {
        setCommunicateForm({
          ...communicateForm,
          trigger_interval_time: val,
        });

        if (val.match(/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/)) {
          setCommunicateFormErrors({
            ...communicateFormErrors,
            trigger_interval_time: "",
          });
        } else {
          setCommunicateFormErrors({
            ...communicateFormErrors,
            trigger_interval_time: "Invalid time interval format",
          });
        }
      }
    } else if (input_type === "sms_name" && val !== "") {
      setCommunicateForm({
        ...communicateForm,
        sms_name: val,
      });
      setCommunicateFormErrors({
        ...communicateFormErrors,
        sms_name: "",
      });
    } else if (val === "") {
      setCommunicateForm({
        ...communicateForm,
        [input_type]: "",
      });
    }
  };

  const handleChannelSelection = (channel: string) => {
    setCommunicateForm({
      ...communicateForm,
      channel: channel,
    });

    setCommunicateFormErrors({
      ...communicateFormErrors,
      channel: "",
    });
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm(communicateForm);
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      try {
        const response = await createCommunication(communicateForm);

        if (response.data.status === "success") {
          // alert(response.data.message);
          handleOpen();
          setCommunicateForm({
            channel: "",
            sms_name: "",
            trigger_point: "",
            trigger_time: "",
            trigger_interval_time: "",
            feedback_name: "",
            sms_message:
              "Please take a moment to shared your feedback about the call with {name}. Click here to give feedback - {link}",
            language: "English",
          });
          setSelectedOptions([]);
          setSelectedFeedbackOptions([]);
          setSelectedOptions2([]);
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.log(error);
        alert(error);
      } finally {
        setIsSubmitting(false);
        setCommunicateFormErrors({});
      }
    } else {
      setCommunicateFormErrors(validationErrors);
      alert(Object.values(validationErrors).join("\n"));
    }
  };

  const validateForm = (form: CommunicateFormTS) => {
    const errors: Partial<CommunicateFormTS> = {};
    if (!form.channel) {
      errors.channel = "Please select a channel/mode.";
    }
    if (!form.sms_name) {
      errors.sms_name = "Please enter sms name.";
    }
    if (!form.trigger_point) {
      errors.trigger_point = "Please select trigger point.";
    }
    if (!form.trigger_time) {
      errors.trigger_time = "Please select trigger time.";
    }
    // if (form.trigger_time === "interval") {
    //   if (!form.trigger_interval_time) {
    //     errors.trigger_interval_time = "Please enter trigger interval time.";
    //   } else if (
    //     !form.trigger_interval_time.match(
    //       /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/
    //     )
    //   ) {
    //     errors.trigger_interval_time =
    //       "Invalid time interval format. Must be HH:MM:SS.";
    //   }
    // }
    if (!form.feedback_name) {
      errors.feedback_name = "Please select feedback name.";
    }
    // if (!form.language) {
    //     errors.language = 'Please select language.';
    // }
    return errors;
  };

  const handleChangeMobileTestSMS = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;

    if (val === "") {
      setMobileTestSMS("");
    } else if (!/^\d+$/.test(val) || val.length > 10) {
      return;
    }

    setMobileTestSMS(val);
  }

  const convertUrl = async (url: string) => {
    const payload = {
      long_url: url,
    }
    try {
      const resp = await shortenSingleUrl(payload);

      if (resp.data.statusCode === 200) {
        return resp.data.response.link;
      } else {
        console.log("Some error...");
      }

    } catch (error) {
      console.log(error);

    }
  }

  const sendSms = async (mobileNumber: string, message: string) => {
    const payload = {
      to: mobileNumber,
      message: message,
    }
    try {
      const resp = await sendSingleSms(payload);

      if (resp.data.statusCode === 200) {
        return resp.data.response.link;
      } else {
        console.log("Some error...");
      }

    } catch (error) {
      console.log(error);

    }
  }

  const Section: React.FC<{
    title: string;
    icon: React.ReactNode;
    children: React.ReactNode;
    testButton?: boolean;
  }> = useCallback(({ title, icon, children, testButton }) => (
    <Paper
      elevation={3}
      // sx={{ mb: 3, overflow: "hidden", borderRadius: "12px", flex: 1, alignItems: "center" }}
      sx={{ mb: 3, borderRadius: "12px", flex: 1, alignItems: "center", width: "100%" }}
    >
      <motion.div
        initial={false}
        animate={{
          background:
            openSection === title.toLowerCase()
              ? "#ffffff"
              : "#ffffff",
        }}
        transition={{ duration: 0.3 }}
        className="tw-flex tw-items-center tw-rounded-lg"
      >
        <Button
          fullWidth
          onClick={() => toggleSection(title.toLowerCase())}
          startIcon={icon}
          endIcon={<ChevronDown className="tw-text-[#919191]" />}
          sx={{
            justifyContent: "flex-start",
            alignContent: "center",
            p: 2,
            textTransform: "none",
            color: "#919191",
            "&:hover": {
              background: "#d4e6f0",
            },
          }}
        >
          <Typography sx={{ color: "#919191" }}>{title}</Typography>
        </Button>
        {/* {testButton &&
          <Button
            // fullWidth
            variant="contained"
            onClick={handleClickTestDialogOpen}
            sx={{
              // justifyContent: "flex-end",
              // alignContent: "center",
              height: "40px",
              width: "150px",
              m: 2,
              textTransform: "none",
              color: "#1565C0",
              "&:hover": {
                background: "linear-gradient(135deg, #BBDEFB, #90CAF9)",
              },
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: "0px !important", color: "white !important" }}>Test SMS</Typography>
          </Button>} */}
      </motion.div>
      <AnimatePresence>
        {openSection === title.toLowerCase() && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Box
              sx={{
                p: 3,
                background: "#ffffff",
              }}
            >
              {children}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Paper>
  ), [openSection, toggleSection]);

  console.log(communicateForm);
  return (
    <Container maxWidth="xl" sx={{marginBottom: "68px"}}>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h5"
          align="left"
          sx={{ color: "#656565", fontWeight: "bold" }}
        >
          Create Communicator
        </Typography>
      </Box>
      <div className="tw-text-left tw-mx-auto tw-rounded-xl tw-shadow-lg tw-p-4 tw-bg-white tw-w-full tw-mb-6 tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-between">
        <img
          src="https://img.freepik.com/free-vector/hand-drawn-business-communication-concept_52683-76159.jpg?t=st=1722929467~exp=1722933067~hmac=399b28f52170043969250644fc7f2f25056a94ea56eef3f52ee91aef4aaabff3&w=996"
          alt="survey"
          className="tw-w-60"
        />
        <p className="tw-text-gray-600 tw-text-lg tw-pl-5 tw-flex tw-justify-center tw-items-center">
          This feature will enable users to communicate survey invitations and
          reminders through various channels, such as sms, email, in-app links,
          and messaging apps like WhatsApp. One can define the different aspects
          of the communicator to ensure maximum participation in the feedback.
        </p>
      </div>
      <Container maxWidth="xl">
        <Section
          title="1. How? - Channel / Mode"
          icon={<MessageSquare size={24} color="#686868" />}
        >
          <Box
            sx={{
              display: "grid",
              gap: 2,
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            }}
          >
            {updatedCommunicationOptions.map((option, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Paper
                  elevation={2}
                  sx={{
                    p: 2,
                    cursor: "pointer",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #03335b",
                    background:
                      communicateForm.channel === option.channel
                        ? "#BBDEFB"
                        : "#ffffff",
                    borderRadius: "8px",
                    transition: "0.3s",
                    "&:hover": {
                      background: "#BBDEFB",
                    },
                  }}
                  // onClick={() => navigate(option.url)}
                  onClick={() => handleChannelSelection(option.channel)}
                >
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    {option.icon}
                    <Typography variant="body1" color="text.secondary" sx={{ ml: 2, fontWeight: "bold", fontSize: "18px" }}>
                      {option.name}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {option.description}
                  </Typography>
                </Paper>
              </motion.div>
            ))}
          </Box>
        </Section>

        <Section
          title="2. When? - Trigger Point"
          icon={<Calendar size={24} color="#686868" />}
        >
          <h2 className="tw-text-left tw-font-semibold tw-text-gray-600 tw-text-lg">Select Trigger Point:</h2>
          <div className="tw-flex tw-flex-wrap tw-flex-row tw-justify-start tw-gap-2">
            {triggerOptionsData.map((trigger) => (
              <motion.div
                key={trigger}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Chip
                  label={trigger
                    .replace(/-/g, " ")
                    .replace(/\b\w/g, (l) => l.toUpperCase())}
                  onClick={() => handleTriggerSelect(trigger)}
                  color={triggerOption === trigger ? "primary" : "default"}
                  sx={{
                    px: 2,
                    py: 3,
                    background:
                      triggerOption === trigger
                        ? "#026fc7"
                        : "#03335b",
                    color: triggerOption === trigger ? "#FFFFFF" : "#FFFFFF",
                    "&:hover": {
                      background: "#2196F3",
                      color: "#FFFFFF",
                    },
                  }}
                />
              </motion.div>
            ))}
          </div>
          {triggerOption !== 'journey-day' &&
            <RadioGroup value={whenOption} onChange={handleWhenOptionChange}>
              <FormControlLabel
                value="immediate"
                control={<Radio sx={{ color: "#1565C0" }} />}
                label="Immediately"
              />
              <FormControlLabel
                value="later"
                control={<Radio sx={{ color: "#1565C0" }} />}
                label="Later Date"
              />
              <FormControlLabel
                value="interval"
                control={<Radio sx={{ color: "#1565C0" }} />}
                label="Post Interval"
              />
            </RadioGroup>
          }
          <AnimatePresence>
            {triggerOption !== 'journey-day' && whenOption === "later" && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <TextField
                  type="date"
                  label="Select Date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  sx={{
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": { borderColor: "#64B5F6" },
                    },
                  }}
                />
                <TextField
                  type="time"
                  label="Select Time"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": { borderColor: "#64B5F6" },
                    },
                  }}
                />
              </motion.div>
            )}
            {triggerOption !== 'journey-day' && whenOption === "interval" && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <TextField
                  type="number"
                  label="After (seconds)"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": { borderColor: "#64B5F6" },
                    },
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
          {triggerOption === "journey-day" && (
            <Box sx={{ mt: 2 }}>
              <Select
                value={journeyDay}
                onChange={handleJourneyDayChange}
                fullWidth
                sx={{
                  mb: 2,
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#64B5F6",
                  },
                }}
              >
                {[1, 7, 15, 30, 45, 60, 75, 90, "custom"].map((day) => (
                  <MenuItem key={day} value={day.toString()}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
              <AnimatePresence>
                {journeyDay === "custom" && (
                  <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    <TextField
                      type="number"
                      label="Custom Day"
                      value={customJourneyDay}
                      onChange={(e) => setCustomJourneyDay(e.target.value)}
                      fullWidth
                      sx={{
                        mb: 2,
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": { borderColor: "#64B5F6" },
                        },
                      }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
              <TextField
                type="time"
                label="Select Time"
                InputLabelProps={{ shrink: true }}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": { borderColor: "#64B5F6" },
                  },
                }}
              />
            </Box>
          )}
        </Section>

        <div className="tw-relative">
          <Section
            title="3. What? - Messaging"
            icon={<MessageCircle size={24} color="#686868" />}
            testButton={true}
          >
            <OutlinedInput
              placeholder="Communicator Name for your reference"
              fullWidth
              id="sms_name"
              aria-describedby="my-helper-text"
              sx={{
                boxSizing: "border-box",
                minWidth: "250px",
                height: "40px",
                marginBottom: "20px",
              }}
              name="sms_name"
              value={communicateForm.sms_name || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChanges(e, "sms_name")
              }
            />
            <RDPSelect
              // defaultValue="template1"
              // fullWidth
              // sx={{
              //   mb: 2,
              //   "&:hover .MuiOutlinedInput-notchedOutline": {
              //     borderColor: "#64B5F6",
              //   },
              // }}
              placeholder="Select your feedback..."
              options={feedbackOptions}
              values={selectedFeedbackOptions}
              onChange={handleFeedbackOptionsChange}
              dropdownHeight="150px"
              style={{
                width: "100%",
                textAlign: "left",
                marginBottom: "20px",
                fontSize: "18px",
              }}
            />
            {/* <MenuItem value="template1">Template 1</MenuItem>
          <MenuItem value="template2">Template 2</MenuItem>
          <MenuItem value="template3">Template 3</MenuItem>
        </RDPSelect> */}
            {selectedFeedbackOptions.length > 0 &&
              <Typography variant="h6" textAlign="start">Message/s</Typography>
            }
            {selectedFeedbackOptions.length > 0 ? (
              smsTranslations.length === 1 ? (
                <TextField
                  dir={smsTranslations[0].language_code === "ar" ? "rtl" : "ltr"}
                  multiline
                  rows={4}
                  // defaultValue="Please take the time to review your experience of the call just received. Click here: <Link>"
                  value={smsTranslations[0].sms_message || ""}
                  onChange={(e) => {
                    const updatedTranslations = [...smsTranslations]; // Make a copy of the current translations
                    updatedTranslations[0] = {
                      ...updatedTranslations[0],
                      sms_message: e.target.value, // Update the sms_message in the first object
                    };
                    setSmsTranslations(updatedTranslations); // Update the state with the new translations
                  }}
                  // style={{
                  //   backgroundColor: "lightgray",
                  // }}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": { borderColor: "#64B5F6" },
                    },
                  }}
                />
              ) : (
                smsTranslations.map((val, i) => {
                  return (
                    <TextField
                      dir={val.language_code === "ar" ? "rtl" : "ltr"}
                      key={i}
                      multiline
                      rows={4}
                      // defaultValue="Please take the time to review your experience of the call just received. Click here: <Link>"
                      value={val.sms_message || ""}
                      onChange={(e) => {
                        const updatedTranslations = [...smsTranslations]; // Make a copy of the current translations
                        updatedTranslations[i] = {
                          ...updatedTranslations[i],
                          sms_message: e.target.value, // Update the sms_message in the first object
                        };
                        setSmsTranslations(updatedTranslations); // Update the state with the new translations
                      }}
                      // style={{
                      //   backgroundColor: "lightgray",
                      // }}
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": { borderColor: "#64B5F6" },
                        },
                        marginBottom: "20px"
                      }}
                    />
                  )
                })
              )
            ) : null}
            {/* <TextField
            multiline
            rows={4}
            // defaultValue="Please take the time to review your experience of the call just received. Click here: <Link>"
            disabled
            value={communicateForm.sms_message || ""}
            // style={{
            //   backgroundColor: "lightgray",
            // }}
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": { borderColor: "#64B5F6" },
              },
            }}
          /> */}
          </Section>
          <Button
            // fullWidth
            variant="contained"
            disabled={smsTranslations.length === 0}
            onClick={handleClickTestDialogOpen}
            sx={{
              // justifyContent: "flex-end",
              // alignContent: "center",
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 2,
              marginTop: "auto",
              marginBottom: "auto",
              height: "40px",
              width: "150px",
              m: 1,
              textTransform: "none",
              color: "#1565C0",
              "&:hover": {
                background: "#90CAF9",
              },
            }}
          >
            <Typography sx={{ marginBottom: "0px !important", color: "white !important" }}>Test SMS</Typography>
          </Button>
        </div>

        <Section
          title="4. Whom? - Audience Segment"
          icon={<Users size={24} color="#686868" />}
        >
          <RadioGroup row value={whomOption} onChange={handleWhomOptionChange}>
            <FormControlLabel
              value="all"
              control={<Radio sx={{ color: "#1565C0" }} />}
              label="All"
            />
            <FormControlLabel
              value="filter"
              control={<Radio sx={{ color: "#1565C0" }} />}
              label="Filter"
            />
          </RadioGroup>
          {whomOption === 'filter' && filters.map((filter, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <TextField
                  label="User Property"
                  value={filter.property}
                  onChange={(e) =>
                    updateFilter(index, "property", e.target.value)
                  }
                  sx={{
                    mr: 1,
                    flexGrow: 1,
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": { borderColor: "#64B5F6" },
                    },
                  }}
                />
                <TextField
                  label="Condition"
                  value={filter.condition}
                  onChange={(e) =>
                    updateFilter(index, "condition", e.target.value)
                  }
                  sx={{
                    mr: 1,
                    flexGrow: 1,
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": { borderColor: "#64B5F6" },
                    },
                  }}
                />
                <TextField
                  label="Value"
                  value={filter.value}
                  onChange={(e) => updateFilter(index, "value", e.target.value)}
                  sx={{
                    mr: 1,
                    flexGrow: 1,
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": { borderColor: "#64B5F6" },
                    },
                  }}
                />
                <IconButton
                  onClick={() => removeFilter(index)}
                  sx={{ color: "#F44336" }}
                >
                  <Minus size={24} />
                </IconButton>
              </Box>
            </motion.div>
          ))}
          {whomOption === 'filter' &&
            <Button
              onClick={addFilter}
              variant="outlined"
              startIcon={<Plus size={24} />}
              sx={{
                color: "#1565C0",
                borderColor: "#1565C0",
                "&:hover": {
                  backgroundColor: "#E3F2FD",
                  borderColor: "#1565C0",
                },
              }}
            >
              Add Filter
            </Button>
          }
        </Section>

        <Section title="5. Preview" icon={<Eye size={24} color="#686868" />}>
          <Paper
            elevation={2}
            sx={{
              p: 3,
              mb: 3,
              background: "linear-gradient(135deg, #ffffff, #f0f4ff)",
              borderRadius: "8px",
              border: "1px solid #03335b",
            }}
          >
            <Typography sx={{ color: "#03335b" }}>
              {/* Please take the time to review your experience of the call just
            received. Click here:{" "}
            <a href="#" style={{ color: "#1565C0", fontWeight: "bold" }}>
              Link
            </a> */}
              {communicateForm.sms_message || ""}
            </Typography>
          </Paper>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<Send size={24} />}
            fullWidth
            sx={{
              background: "#03335b",
              "&:hover": {
                background: "#0D47A1",
              },
              borderRadius: "8px",
              py: 1.5,
            }}
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Send Message
          </Button>
        </Section>
      </Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div>
            <CustomAlertBox
              type="success"
              title="Thank you"
              message="Your feedback has been submitted!!"
            />
          </div>
        </Fade>
      </Modal>

      <Dialog
        open={openTestDialog}
        onClose={handleCloseTestDialog}
        PaperProps={{
          component: 'form',
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            try {
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries()) as { mobile_number: string };
              const mobileNumber = formJson.mobile_number;

              if (mobileNumber.length !== 10 || isNaN(Number(mobileNumber))) {
                alert("Mobile number should be exactly 10 digits and contain only numeric characters.");
                return;
              }

              setMobileTestSMSDisabled(true);

              const shortUrl = await convertUrl(`https://feedback360.goqii.com/survey-form/${smsTranslations[0].id}/${mobileNumber}`);

              const sendMessages = smsTranslations.map(s => ({
                ...s,
                sms_message: s.sms_message ? s.sms_message.toString().replace(/{link}/g, shortUrl) : ''
              }));

              await Promise.all(sendMessages.map(async (val) => {
                if (val.sms_message) {
                  await sendSms(mobileNumber, val.sms_message);
                }
              }));

              setTimeout(() => {
                alert("Message/s Sent Successfully.");

                setMobileTestSMSDisabled(false);
                handleCloseTestDialog();
                window.location.reload();
              }, 2000);

            } catch (error) {
              console.error("An error occurred while sending messages:", error);
              alert("Failed to send messages. Please try again.");
            }
          },
        }}
      >
        <DialogContent>
          <DialogContentText>
            To send SMS, please enter the mobile number.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            name="mobile_number"
            label="Mobile Number"
            type="text"
            fullWidth
            variant="standard"
            value={mobileTestSMS}
            onChange={handleChangeMobileTestSMS}
          />
          <Button
            variant="contained"
            type="submit"
            sx={{ marginTop: "10px" }}
            disabled={(mobileTestSMS === "" && mobileTestSMS.length !== 10) || mobileTestSMSDisabled}
          >
            Send
          </Button>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Subscribe</Button>
        </DialogActions> */}
      </Dialog>
    </Container>
  );
};

export default ChooseCommunicationOptions;
